import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import Spinner from '../../layout/Spinner';
import SponsorshipActionsAdmin from './SponsorshipActionsAdmin';
import Tree, { withStyles } from 'react-vertical-tree';
import { getAllUsers, deleteUser, getPartners, getGenealogies, getSponsorships, validateInvoice, validatePayment } from '../../../actions/sponsorship';
import { getTransactions, getInvoice } from '../../../actions/transaction';
import Modal from "react-bootstrap/Modal";
import ModalBody from "react-bootstrap/ModalBody";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalFooter from "react-bootstrap/ModalFooter";
import ModalTitle from "react-bootstrap/ModalTitle";
import Pagination from '../../layout/Pagination';
import pdfIcon from '../../../img/pdf.png';
import { Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';


const SponsorshipTreeAdmin = ({ getAllUsers, getSponsorships, getPartners, deleteUser, validateInvoice, validatePayment, getGenealogies, getTransactions,
  getInvoice, auth: { user, theme }, transaction: { transactions }, sponsorship: { admin_partners, partners, all_users, level_affiliation_other, level_affiliation_repositioned_other, genealogies_other, bonus_other, affiliations_commissions_number_year_other, genealogies_this_month_other, bonus_genealogy_other, loading } }) => {

 // const [newIndex, setNewIndex] = useState(0);
  const [modalsOpen, setModalIsOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalBody, setModalBody] = useState("");
  const [modalFirstButton, setModalFirstButton] = useState("");
  const [modalSecondButton, setModalSecondButton] = useState("");
  const [userIdDelete, setUserIdDelete] = useState("");
  const [switchTree, setSwitchTree] = useState(1);
  const [selectedUser, setSelectedUser] = useState();

  const [currentPage, setCurrentPage] = useState(1);
  const [usersPerPage] = useState(10);

  const [currentPageTransaction, setCurrentPageTransaction] = useState(1);
  const [transactionsPerPage] = useState(5);

  const [criteria, setCriteria] = useState("A");
  const [searchCriteria, setSearchCriteria] = useState("S");
  const [searchField, setSearchField] = useState("");

  const [unpaidChange, setUnpaidChange] = useState(false);
  const [penaltyChange, setPenaltyChange] = useState(false);
  const [oldAccountChange, setOldAccountChange] = useState(false);
  const [genderMaleChange, setGenderMaleChange] = useState(false);
  const [genderFemaleChange, setGenderFemaleChange] = useState(false);

  const [attachment_type, setAttachment_type] = useState("");
  const [attachment_name_front, setAttachment_name_front] = useState("");
  const [attachment_name_back, setAttachment_name_back] = useState("");

  const [deletionCause, setDeletionCause] = useState("");
  const [deletionAlertCause, setDeletionAlertCause] = useState("");


  const [paymentModalsOpen, setPaymentModalIsOpen] = useState(false);
  const [paymentModalTitle, setPaymentModalTitle] = useState("");
  const [paymentModalBody, setPaymentModalBody] = useState("");
  const [userPayment, setUserPayment] = useState("");

  /*const styles = {
    lines: {
      color: '#1890ff',
      height: '90px',
    },
    node: {
      backgroundColor: '#1890ff',
      border: '1px solid #1890ff',
    },
    text: {
      color: '#fff',
    }
  }*/
  const styles = {
    lines: {
      color: theme === "light" ? '#bc9f51' : '#edd99a',
      height: '90px',
    },
    node: {
      backgroundColor: theme === "light" ? '#bc9f51' : '#edd99a',
      border: '1px solid',
      borderColor: theme === "light" ? '#bc9f51' : '#edd99a',
    },
    text: {
      color: theme === "light" ? '#fff' : '#000',
      fontWeight: 'bold'
    }
  }
  const StyledTree = withStyles(styles)(Tree)
  

  let history = useHistory();

/*  useEffect(() => {
    document.body.style = 'background: #EDAE6A'; //#DCDCDC

    // returned function will be called on component unmount 
    return () => {
      document.body.style = 'background: #D3D3D3'; //#DCDCDC
    }
  }, []); */

  useEffect(() => {
    getAllUsers(user._id);
  }, [getAllUsers, user._id]);

  useEffect(() => {
    getPartners();
  }, [getPartners]);

  useEffect(() => {
    getGenealogies(user._id, false, true);
  }, [getGenealogies, user._id]);

  useEffect(() => {
    getTransactions(user._id);
  }, [getTransactions, user._id]);

  useEffect(() => {
    getSponsorships(user._id, false, true);
  }, [getSponsorships, user._id]);



  const { t } = useTranslation();

  if ( user.role !== 0 ) {
    return <Redirect to="/dashboard" />;
  }

  const indexOfLastTransaction = currentPageTransaction * transactionsPerPage;
  const indexOfFirstTransaction = indexOfLastTransaction - transactionsPerPage;

  var currentTransactions = [];

  //var penalityCounter = 1

  if (transactions && transactions.length > 0) {
    currentTransactions = transactions.slice(indexOfFirstTransaction, indexOfLastTransaction);

  /*  for (var counter = 0; counter < transactions.length; counter++) {
      if (transactions[counter].title === "server_12_transaction") {
        penalityCounter++;
      }
    } */
  }

  const paginateTransaction = pageNum => setCurrentPageTransaction(pageNum);

  const nextPageTransaction = () => setCurrentPageTransaction(currentPageTransaction === Math.ceil(transactions.length / transactionsPerPage) ? currentPageTransaction : currentPageTransaction + 1);

  const prevPageTransaction = () => setCurrentPageTransaction(currentPageTransaction > 1 ? currentPageTransaction - 1 : currentPageTransaction);

  const firstPageTransaction = () => setCurrentPageTransaction(1);

  const lastPageTransaction = () => setCurrentPageTransaction(Math.ceil(transactions.length / transactionsPerPage));

  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  var currentUsers = [];
  var sortedUsers = [];
  var childrens = 0;
  var already_repositioned = 0;

  if (all_users && all_users.length > 0) {
    if (!selectedUser) {
    setSelectedUser(all_users[0])
    }
    let tempData = [...all_users].reverse();
    for (var i = 0; i < tempData.length; i++) {
      if (sortedUsers[sortedUsers.length - 1]) {
        tempData[i].number = sortedUsers[sortedUsers.length - 1].number + 1;
      }
      else {
        tempData[i].number = 1
      }
      already_repositioned = 0;
      if (findRepositioned(tempData[i].email, tempData[i].new_id)) {
        already_repositioned = 1;
      }
      childrens = 0;
    for (var j = 0; j < tempData[i].childs.length; j++) {
      if (tempData[i].childs[j].status === "D") {
        childrens++;
      }
    }
    tempData[i].penalty = childrens;
    tempData[i].already_repositioned = already_repositioned;

    if (!unpaidChange || (unpaidChange === true && tempData[i].payed_status < 3)) {
    if (!penaltyChange || (penaltyChange === true && tempData[i].penalty > 0)) {

      if (!oldAccountChange || (oldAccountChange === true && tempData[i].already_repositioned !== 0)) {

      if ((!genderMaleChange && !genderFemaleChange) || (genderMaleChange === true && tempData[i].gender === "M") ||  (genderFemaleChange === true && tempData[i].gender === "F")) {

    if ((criteria === "A") || (criteria === "C" && tempData[i].status === "C")
    || (criteria === "AM" && tempData[i].status === "A")
    || (criteria === "B" && tempData[i].status === "B")
    || (criteria === "M" && tempData[i].status.charAt(0) === "M" && tempData[i].date_repositioning === "")
    || (criteria === "D" && tempData[i].status === "D")
    || (criteria === "J" && tempData[i].status === "J")
    || (criteria === "R" /*&& tempData[i].status.charAt(1) === "*")*/ && tempData[i].status.charAt(0) === "M" && tempData[i].date_repositioning !== ""))
    {
      if (searchField) {
         if (searchCriteria === "R") {
          if (tempData[i].new_id.toString().startsWith(searchField.toString())) {
            sortedUsers.push(tempData[i]);
          }
          }
          else if (searchCriteria === "S") {
        if (tempData[i].username.toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").startsWith(searchField.toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""))) {
          sortedUsers.push(tempData[i]);
        }
      }
      else if (searchCriteria === "P") {
        if ((tempData[i].verticalLevel.toString() + tempData[i].horizontalLevel.toString()).startsWith(searchField.toString())) {
          sortedUsers.push(tempData[i]);
        }
        }
        else if (searchCriteria === "G") {
        if (tempData[i].parent_username.toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").startsWith(searchField.toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""))) {
          sortedUsers.push(tempData[i]);
        }
        }
        else if (searchCriteria === "C") {
        if (t(`Country.${tempData[i].country}`).toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").startsWith(searchField.toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""))) {
          sortedUsers.push(tempData[i]);
        }
      }
      else if (searchCriteria === "D") {

        var dateDay = tempData[i].date_creation.substring(0, 10).split("-")
        var finalDateDay = ""
        if (tempData[i].date_creation !== "") {
          finalDateDay = dateDay[1] + "-" + dateDay[2] + "-" + dateDay[0]
          if (t('Language.Lang') === "Français" || t('Language.Lang') === "Italiano") {
            finalDateDay = dateDay[2] + "-" + dateDay[1] + "-" + dateDay[0];
          }
        }
        else {
          dateDay = tempData[i].date_repositioning.substring(0, 10).split("-")
          finalDateDay = dateDay[1] + "-" + dateDay[2] + "-" + dateDay[0]
          if (t('Language.Lang') === "Français" || t('Language.Lang') === "Italiano") {
            finalDateDay = dateDay[2] + "-" + dateDay[1] + "-" + dateDay[0];
          }
        }
        

      if (finalDateDay.startsWith(searchField)) {
        sortedUsers.push(tempData[i]);
      }
    }
    else if (searchCriteria === "M") {
      var dateMonth = tempData[i].date_creation.substring(0, 10).split("-")
      if (tempData[i].date_creation !== "") {
        dateMonth = dateMonth[1] + "-" + dateMonth[0]
      }
      else {
        dateMonth = tempData[i].date_repositioning.substring(0, 10).split("-")
        dateMonth = dateMonth[1] + "-" + dateMonth[0]
      }

    if (dateMonth.startsWith(searchField)) {
      sortedUsers.push(tempData[i]);
    }
  }
  else if (searchCriteria === "Y") {

    var dateYear = ""
    if (tempData[i].date_creation !== "") {
      dateYear = tempData[i].date_creation.substring(0, 10).split("-")[0]
    }

    else {
      dateYear = tempData[i].date_repositioning.substring(0, 10).split("-")[0]
    }

  if (dateYear.startsWith(searchField)) {
    sortedUsers.push(tempData[i]);
  }
}
      }
      else {
      sortedUsers.push(tempData[i]);
      }
    }
    }
  }
  }
}
  }

  /*  sortedUsers.sort((a, b) => {
    /*  childrens = 0;
      for (var i = 0; i < a.childs.length; i++) {
        if (a.childs[i].status === "D") {
          childrens++;
        }
      }
      a.penality = childrens; */
 /*     return 0;
    }); */


    currentUsers = sortedUsers.slice(indexOfFirstUser, indexOfLastUser);
  }

  const paginate = pageNum => setCurrentPage(pageNum);

  const nextPage = () => setCurrentPage(currentPage === Math.ceil(sortedUsers.length / usersPerPage) ? currentPage : currentPage + 1);

  const prevPage = () => setCurrentPage(currentPage > 1 ? currentPage - 1 : currentPage);

  const firstPage = () => setCurrentPage(1);

  const lastPage = () => setCurrentPage(Math.ceil(sortedUsers.length / usersPerPage));


  function findRepositioned (email, new_id) {
    return all_users.find(element => element.email === email && element.new_id > new_id && element.status !== "D" && element.status !== "J")
} 

  function handleCriteriaChange (changeEvent) {
    setCriteria(changeEvent.currentTarget.value);
    setCurrentPage(1);
}

function handleSearchCriteriaChange (changeEvent) {

  if ((changeEvent.currentTarget.value === "R" || changeEvent.currentTarget.value === "P" || changeEvent.currentTarget.value === "D" || changeEvent.currentTarget.value === "M" || changeEvent.currentTarget.value === "Y") && isNaN(searchField)) {
    setSearchField("");
  }
  else if ((changeEvent.currentTarget.value === "S" || changeEvent.currentTarget.value === "G" || changeEvent.currentTarget.value === "C") && !isNaN(searchField)) {
    setSearchField("");
  }

  setSearchCriteria(changeEvent.currentTarget.value);

  setCurrentPage(1);
}

function handleUnpaidChange () {
  setUnpaidChange(!unpaidChange);
  setCurrentPage(1);
}

function handlePenaltyChange () {
  setPenaltyChange(!penaltyChange);
  setCurrentPage(1);
}

function handleOldAccountChange () {
  setOldAccountChange(!oldAccountChange);
  setCurrentPage(1);
}

function handleGenderMaleChange () {
  if (!genderMaleChange && genderFemaleChange) {
    setGenderMaleChange(false);
    setGenderFemaleChange(false);
  }
  setGenderMaleChange(!genderMaleChange);
  setCurrentPage(1);
}

function handleGenderFemaleChange () {
  if (genderMaleChange && !genderFemaleChange) {
    setGenderMaleChange(false);
    setGenderFemaleChange(false);
  }
  setGenderFemaleChange(!genderFemaleChange);
  setCurrentPage(1);
}

  var newUserArray = [];


  if(partners && selectedUser) {

     newUserArray = [{
      "id" : selectedUser._id,
      "new_id":selectedUser.new_id,
      "parent":null,
      "name":selectedUser.name ? selectedUser.name : selectedUser.username ? selectedUser.username : user.username,
      "parent_id":selectedUser.parent_id,
      "parent_username":selectedUser.parent_username,
      "first_name":selectedUser.first_name,
      "last_name":selectedUser.last_name,
      "country":selectedUser.country,
      "gender":selectedUser.gender,
      "date_of_birth":selectedUser.date_of_birth,
      "deletion_cause":selectedUser.deletion_cause,
      "phone_number":selectedUser.phone_number,
      "address":selectedUser.address,
      "additional_address":selectedUser.additional_address,
      "city":selectedUser.city,
      "postal_code":selectedUser.postal_code,
      "attachment_type":selectedUser.attachment_type,
      "attachment_name_front":selectedUser.attachment_name_front,
      "attachment_name_back":selectedUser.attachment_name_back,
      "date_creation":selectedUser.date_creation,
      "date_member":selectedUser.date_member,
      "date_repositioning":selectedUser.date_repositioning,
      "confirmed":selectedUser.confirmed,
      "status":selectedUser.status,
      "email":selectedUser.email,
      "verticalLevel":selectedUser.verticalLevel,
      "horizontalLevel":selectedUser.horizontalLevel,
      "children":partners
    }];

 /* newUserArray = jsonArr.map(
    
      obj => {
          return { 
            
              "id" :obj._id,
              "new_id":obj.new_id,
              "parent":null,
              "name":obj.name,
              "parent_username":obj.parent_username,
              "first_name":obj.first_name,
              "last_name":obj.last_name,
              "country":obj.country,
              "gender":obj.gender,
              "date_of_birth":obj.date_of_birth,
              "status":obj.status,
              "email":obj.email,
              "verticalLevel":obj.verticalLevel,
              "horizontalLevel":obj.horizontalLevel,
              "children":partners
          }
      }
    ); */
  }
 
  function isFunction(functionToCheck) {
    return functionToCheck && {}.toString.call(functionToCheck) === '[object Function]';
   }

  var oldUsers = 0
  var oldUsersAll = 0
  var oldUsersAffiliations = 0

  const showTableGenealogy =
      
  <Fragment>



<div
style={{
display: "flex",
justifyContent: "center",
alignItems: "center"
}}>

<div className="scrollme"> 

<table id="tbl" style={{ width : 950 }} className={`table table-bordered  table-hover table-striped " ${theme === "light" ? "" : "table-dark"}`}>
<thead  className={theme === "light" ? "thead-dark" : "thead-light"}  >
<tr>
  <th scope="col" style={{ width : 250 }}>{t('AdminSponsorship.Levels')}</th>
  <th scope="col">{t('AdminSponsorship.Subscribers')}</th>
  <th scope="col">{t('AdminSponsorship.Matrix_commissions')}</th>
  <th scope="col">{t('AdminSponsorship.Training_bonuses')}</th>
  <th scope="col">{t('AdminSponsorship.Monthly_income')}</th>
</tr>
</thead>
<tbody>
<tr>
  <th scope="row">1</th>
  <td>{genealogies_other && !isNaN(genealogies_other[0]) && bonus_other && genealogies_this_month_other ? genealogies_other[0] + genealogies_this_month_other[0] : t('AdminSponsorship.Calculating') + " ..."}</td>
  <td>{genealogies_other && !isNaN(genealogies_other[0]) && bonus_other ? (genealogies_other[0] * 6) + "€": t('AdminSponsorship.Calculating') + " ..."}</td>
  {// UNCOMMENT pre-opening ON FIRST MONTH <td>0€</td>
  }
  <td>{level_affiliation_other && !isNaN(level_affiliation_other[0]) && bonus_other ? level_affiliation_other[0] * 100 + "€": t('AdminSponsorship.Calculating') + " ..."}</td>
  <td>{genealogies_other && !isNaN(genealogies_other[0]) && bonus_other && level_affiliation_other ? /* UNCOMMENT pre-opening ON FIRST MONTH*/ (genealogies_other[0] * 6) + level_affiliation_other[0] * 100 + "€" : t('AdminSponsorship.Calculating') + " ..."}</td>
</tr>
<tr>
<th scope="row">2</th>
  <td>{genealogies_other && !isNaN(genealogies_other[1]) && bonus_other && genealogies_this_month_other ? genealogies_other[1] + genealogies_this_month_other[1] : t('AdminSponsorship.Calculating') + " ..."}</td>
  <td>{genealogies_other && !isNaN(genealogies_other[1]) && bonus_other ? (genealogies_other[1] * 6) + "€": t('AdminSponsorship.Calculating') + " ..."}</td>
 {// UNCOMMENT pre-opening ON FIRST MONTH  <td>0€</td>
}
  <td>{level_affiliation_other && !isNaN(level_affiliation_other[1]) && bonus_other ? level_affiliation_other[1] * 100 + "€": t('AdminSponsorship.Calculating') + " ..."}</td>
  <td>{genealogies_other && !isNaN(genealogies_other[1]) && bonus_other && level_affiliation_other ? /* UNCOMMENT pre-opening ON FIRST MONTH*/ (genealogies_other[1] * 6) + level_affiliation_other[1] * 100 + "€" : t('AdminSponsorship.Calculating') + " ..."}</td>
</tr>
<tr>
  <th scope="row">3</th>
  <td>{genealogies_other && !isNaN(genealogies_other[2]) && bonus_other && genealogies_this_month_other ? genealogies_other[2] + genealogies_this_month_other[2] : t('AdminSponsorship.Calculating') + " ..."}</td>
  <td>{genealogies_other && !isNaN(genealogies_other[2]) && bonus_other ? (genealogies_other[2] * 6) + "€": t('AdminSponsorship.Calculating') + " ..."}</td>
 {// UNCOMMENT pre-opening ON FIRST MONTH  <td>0€</td>
}
  <td>{level_affiliation_other && !isNaN(level_affiliation_other[2]) && bonus_other ? level_affiliation_other[2] * 100 + "€": t('AdminSponsorship.Calculating') + " ..."}</td>
  <td>{genealogies_other && !isNaN(genealogies_other[2]) && bonus_other && level_affiliation_other ? /* UNCOMMENT pre-opening ON FIRST MONTH*/ (genealogies_other[2] * 6) + level_affiliation_other[2] * 100 + "€" : t('AdminSponsorship.Calculating') + " ..."}</td>
</tr>
<tr>
  <th scope="row">4</th>
  <td>{genealogies_other && !isNaN(genealogies_other[3]) && bonus_other && genealogies_this_month_other ? genealogies_other[3] + genealogies_this_month_other[3] : t('AdminSponsorship.Calculating') + " ..."}</td>
  <td>{genealogies_other && !isNaN(genealogies_other[3]) && bonus_other ? (genealogies_other[3] * 6) + "€": t('AdminSponsorship.Calculating') + " ..."}</td>
  {// UNCOMMENT pre-opening ON FIRST MONTH <td>0€</td>
}
  <td>{level_affiliation_other && !isNaN(level_affiliation_other[3]) && bonus_other ? level_affiliation_other[3] * 100 + "€": t('AdminSponsorship.Calculating') + " ..."}</td>
  <td>{genealogies_other && !isNaN(genealogies_other[3]) && bonus_other && level_affiliation_other ? /* UNCOMMENT pre-opening ON FIRST MONTH*/ (genealogies_other[3] * 6) + level_affiliation_other[3] * 100 + "€" : t('AdminSponsorship.Calculating') + " ..."}</td>
</tr>
<tr>
  <th scope="row">5</th>
  <td>{genealogies_other && !isNaN(genealogies_other[4]) && bonus_other && genealogies_this_month_other ? genealogies_other[4] + genealogies_this_month_other[4] : t('AdminSponsorship.Calculating') + " ..."}</td>
  <td>{genealogies_other && !isNaN(genealogies_other[4]) && bonus_other ? (genealogies_other[4] * 6) + "€": t('AdminSponsorship.Calculating') + " ..."}</td>
 {// UNCOMMENT pre-opening ON FIRST MONTH  <td>0€</td>
}
  <td>{level_affiliation_other && !isNaN(level_affiliation_other[4]) && bonus_other ? level_affiliation_other[4] * 100 + "€": t('AdminSponsorship.Calculating') + " ..."}</td>
  <td>{genealogies_other && !isNaN(genealogies_other[4]) && bonus_other && level_affiliation_other ? /* UNCOMMENT pre-opening ON FIRST MONTH*/ (genealogies_other[4] * 6) + level_affiliation_other[4] * 100 + "€" : t('AdminSponsorship.Calculating') + " ..."}</td>
</tr>
<tr>
  <th scope="row">6</th>
  <td>{genealogies_other && !isNaN(genealogies_other[5]) && bonus_other && genealogies_this_month_other ? genealogies_other[5] + genealogies_this_month_other[5] : t('AdminSponsorship.Calculating') + " ..."}</td>
  <td>{genealogies_other && !isNaN(genealogies_other[5]) && bonus_other ? (genealogies_other[5] * 6) + "€": t('AdminSponsorship.Calculating') + " ..."}</td>
 {// UNCOMMENT pre-opening ON FIRST MONTH  <td>0€</td>
}
  <td>{level_affiliation_other && !isNaN(level_affiliation_other[5]) && bonus_other ? level_affiliation_other[5] * 100 + "€": t('AdminSponsorship.Calculating') + " ..."}</td>
  <td>{genealogies_other && !isNaN(genealogies_other[5]) && bonus_other && level_affiliation_other ? /* UNCOMMENT pre-opening ON FIRST MONTH*/ (genealogies_other[5] * 6) + level_affiliation_other[5] * 100 + "€" : t('AdminSponsorship.Calculating') + " ..."}</td>
</tr>
<tr>
  <th scope="row">7</th>
  <td>{genealogies_other && !isNaN(genealogies_other[6]) && bonus_other && genealogies_this_month_other ? genealogies_other[6] + genealogies_this_month_other[6] : t('AdminSponsorship.Calculating') + " ..."}</td>
  <td>{genealogies_other && !isNaN(genealogies_other[6]) && bonus_other ? (genealogies_other[6] * 6) + "€": t('AdminSponsorship.Calculating') + " ..."}</td>
{// UNCOMMENT pre-opening ON FIRST MONTH   <td>0€</td>
}
  <td>{level_affiliation_other && !isNaN(level_affiliation_other[6]) && bonus_other ? level_affiliation_other[6] * 100 + "€": t('AdminSponsorship.Calculating') + " ..."}</td>
  <td>{genealogies_other && !isNaN(genealogies_other[6]) && bonus_other && level_affiliation_other ? /* UNCOMMENT pre-opening ON FIRST MONTH*/ (genealogies_other[6] * 6) + level_affiliation_other[6] * 100 + "€" : t('AdminSponsorship.Calculating') + " ..."}</td>
</tr>
<tr>
  <th scope="row">{t('AdminSponsorship.Affiliate_commissions')}</th>
  <td>{genealogies_other && !isNaN(genealogies_other[6]) && bonus_other && genealogies_this_month_other ? affiliations_commissions_number_year_other : t('AdminSponsorship.Calculating') + " ..."}</td>
  <td>{genealogies_other && !isNaN(genealogies_other[6]) && bonus_other ? "" : t('AdminSponsorship.Calculating') + " ..."}</td>
{// UNCOMMENT pre-opening ON FIRST MONTH   <td>0€</td>
}
  <td>{level_affiliation_other && !isNaN(level_affiliation_other[6]) && bonus_other ? "" : t('AdminSponsorship.Calculating') + " ..."}</td>
  <td>{genealogies_other && !isNaN(genealogies_other[6]) && bonus_other && level_affiliation_other ? /* UNCOMMENT pre-opening ON FIRST MONTH*/ (affiliations_commissions_number_year_other * 6) + "€" : t('AdminSponsorship.Calculating') + " ..."}</td>
</tr>
<tr>
  <th scope="row">{bonus_other && bonus_other.length > 0 ? t('AdminSponsorship.Matrix') + " " + (bonus_other.length + 1) : t('AdminSponsorship.Total')}</th>
  <td>
  {genealogies_other && !isNaN(genealogies_other[0]) && bonus_other && genealogies_this_month_other ? (genealogies_other[0] + genealogies_other[1] +
  genealogies_other[2] + genealogies_other[3] + genealogies_other[4] +
  genealogies_other[5] + genealogies_other[6] + genealogies_this_month_other[0]
  + genealogies_this_month_other[1] + genealogies_this_month_other[2] + genealogies_this_month_other[3]
  + genealogies_this_month_other[4] + genealogies_this_month_other[5] + genealogies_this_month_other[6]) : t('AdminSponsorship.Calculating') + " ..."}
  </td>
  
   <td>
   {genealogies_other && !isNaN(genealogies_other[0]) && bonus_other ? ((genealogies_other[0] * 6) + (genealogies_other[1] * 6) +
  (genealogies_other[2] * 6) + (genealogies_other[3] * 6) + (genealogies_other[4] * 6) +
  (genealogies_other[5] * 6) + (genealogies_other[6] * 6)) + "€" : t('AdminSponsorship.Calculating') + " ..."}
  </td>
  { /*UNCOMMENT pre-opening ON FIRST MONTH <td>0€</td>*/
}
  <td>
  {level_affiliation_other && !isNaN(level_affiliation_other[0]) && bonus_other ? (level_affiliation_other[0] * 100 + level_affiliation_other[1] * 100 +
  level_affiliation_other[2] * 100 + level_affiliation_other[3] * 100 + level_affiliation_other[4] * 100 +
  level_affiliation_other[5] * 100 + level_affiliation_other[6] * 100) + "€" : t('AdminSponsorship.Calculating') + " ..."
  }
  </td>
  <td>
  { genealogies_other && !isNaN(genealogies_other[0]) && bonus_other && level_affiliation_other ? (/* UNCOMMENT pre-opening ON FIRST MONTH*/ (genealogies_other[0] * 6) + (genealogies_other[1] * 6) +
  (genealogies_other[2] * 6) + (genealogies_other[3] * 6) + (genealogies_other[4] * 6) +
  (genealogies_other[5] * 6) + (genealogies_other[6] * 6) +
  level_affiliation_other[0] * 100 + level_affiliation_other[1] * 100 +
  level_affiliation_other[2] * 100 + level_affiliation_other[3] * 100 + level_affiliation_other[4] * 100 +
  level_affiliation_other[5] * 100 + level_affiliation_other[6] * 100 + affiliations_commissions_number_year_other * 6 )
  + "€" : t('AdminSponsorship.Calculating') + " ..."
    }
  </td>
</tr>
<div style={{ display : (bonus_genealogy_other && bonus_genealogy_other !== 0) ? "" : "none" }}><br/></div>
<tr>
  <th style={{ display : (bonus_genealogy_other && bonus_genealogy_other !== 0) ? "" : "none" }} scope="row">{t('AdminSponsorship.Repositioning_bonus')}</th>
  <td style={{ display : (bonus_genealogy_other && bonus_genealogy_other !== 0) ? "" : "none" }} colSpan={3}></td>
  <td style={{ display : (bonus_genealogy_other && bonus_genealogy_other !== 0) ? "" : "none" }}>{bonus_genealogy_other + "€"}</td>
  </tr>
</tbody>
</table>







{/*<h1 className='large text-primary' style={{ display : bonus && bonus.length > 0 ? "" : "none" }}>{t('AdminSponsorship.Total_all_matrices')}</h1>*/}


    
<table  style={{ display : bonus_other && bonus_other.length > 0 ? "" : "none", width : 950 }} id="tbl" className={`table table-bordered  table-hover table-striped " ${theme === "light" ? "" : "table-dark"}`}>
<thead  className={theme === "light" ? "thead-dark" : "thead-light"} >
<tr>
  <th scope="col" style={{ width : 250 }}>{t('AdminSponsorship.Full_matrices')}</th>
  <th scope="col">{t('AdminSponsorship.Subscribers')}</th>
  <th scope="col">{t('AdminSponsorship.Matrix_commissions')}</th>
  <th scope="col">{t('AdminSponsorship.Training_bonuses')}</th>
  <th scope="col">{t('AdminSponsorship.Monthly_income')}</th>
</tr>
</thead>
<tbody>


{(!bonus_other || !level_affiliation_repositioned_other) || ((bonus_other && bonus_other.length === 0) || (level_affiliation_repositioned_other && level_affiliation_repositioned_other.length === 0)) ? 
  <>
</>
:
(bonus_other && isFunction(bonus_other.map)) ?
bonus_other.map(( get_bonus, index ) => {
  oldUsers += get_bonus ? get_bonus !== undefined ? get_bonus.user_number : 0 : 0
  oldUsersAll += get_bonus ? get_bonus !== undefined ? get_bonus.user_number_all : 0 : 0
  oldUsersAffiliations += level_affiliation_repositioned_other[index] ? level_affiliation_repositioned_other[index] !== undefined ? level_affiliation_repositioned_other[index].user_number : 0 : 0
return (
<tr key={index}>
<th scope="row">{t('AdminSponsorship.Matrix') + " " + (bonus_other && bonus_other.length ? bonus_other.length - index : index)}</th>
<td>{get_bonus ? get_bonus !== undefined ? get_bonus.user_number + oldUsersAll : t('AdminSponsorship.Calculating') + " ..." : t('AdminSponsorship.Calculating') + " ..."}</td>
<td>{get_bonus ? get_bonus !== undefined ? get_bonus.user_number * 6 + "€" : t('AdminSponsorship.Calculating') + " ..." : t('AdminSponsorship.Calculating') + " ..."}</td>
<td>{level_affiliation_repositioned_other[index] ? level_affiliation_repositioned_other[index] !== undefined ? level_affiliation_repositioned_other[index].user_number * 100 + "€" : t('AdminSponsorship.Calculating') + " ..." : t('AdminSponsorship.Calculating') + " ..."}</td>
<td>{get_bonus ? get_bonus !== undefined ? get_bonus.user_number * 6 + level_affiliation_repositioned_other[index].user_number * 100 + "€" : t('AdminSponsorship.Calculating') + " ..." : t('AdminSponsorship.Calculating') + " ..."}</td>
</tr>
);
}) : null }

<tr>
  <th scope="row">{t('AdminSponsorship.Total')}</th>
  <td>
  {genealogies_other && !isNaN(genealogies_other[0]) && genealogies_this_month_other && bonus_other && level_affiliation_repositioned_other ? (genealogies_other[0] + genealogies_other[1] +
  genealogies_other[2] + genealogies_other[3] + genealogies_other[4] +
  genealogies_other[5] + genealogies_other[6] + genealogies_this_month_other[0]
  + genealogies_this_month_other[1] + genealogies_this_month_other[2] + genealogies_this_month_other[3]
  + genealogies_this_month_other[4] + genealogies_this_month_other[5] + genealogies_this_month_other[6] + oldUsers + oldUsersAll) : t('AdminSponsorship.Calculating') + " ..."}
  </td>
  <td>
  {genealogies_other && !isNaN(genealogies_other[0]) && bonus_other ? ((genealogies_other[0] * 6) + (genealogies_other[1] * 6) +
  (genealogies_other[2] * 6) + (genealogies_other[3] * 6) + (genealogies_other[4] * 6) +
  (genealogies_other[5] * 6) + (genealogies_other[6] * 6) + (oldUsers * 6)) + "€" : t('AdminSponsorship.Calculating') + " ..."}
  </td>
  <td>

  {level_affiliation_other && !isNaN(level_affiliation_other[0]) && level_affiliation_repositioned_other? (level_affiliation_other[0] * 100 + level_affiliation_other[1] * 100 +
  level_affiliation_other[2] * 100 + level_affiliation_other[3] * 100 + level_affiliation_other[4] * 100 +
  level_affiliation_other[5] * 100 + level_affiliation_other[6] * 100 + oldUsersAffiliations * 100) + "€" : t('AdminSponsorship.Calculating') + " ..."
  }
  </td>
  <td>
  {genealogies_other && !isNaN(genealogies_other[0]) && level_affiliation_other && bonus_other && level_affiliation_repositioned_other ? ((genealogies_other[0] * 6) + (genealogies_other[1] * 6) +
  (genealogies_other[2] * 6) + (genealogies_other[3] * 6) + (genealogies_other[4] * 6) +
  (genealogies_other[5] * 6) + (genealogies_other[6] * 6) +
  level_affiliation_other[0] * 100 + level_affiliation_other[1] * 100 +
  level_affiliation_other[2] * 100 + level_affiliation_other[3] * 100 + level_affiliation_other[4] * 100 +
  level_affiliation_other[5] * 100 + level_affiliation_other[6] * 100
  + oldUsers * 6 + oldUsersAffiliations * 100 + bonus_genealogy_other)
  + "€" : t('AdminSponsorship.Calculating') + " ..."
    }
  </td>
</tr>
  </tbody>
</table>

</div>
</div>

</Fragment>;


   // const  data = [




    /*  {id: 1, name: 'company', parent: null, children: [
        {id: 2, parent: {id: 1}, name: 'subcompany1', children: [
          {id: 4, parent: {id: 2}, name: 'example-company', children: [
          ]}
        ]},
        {id: 4, parent: {id: 2}, name: 'subcompany2', children: [
        ]}
      ]}, */
      








     /*  {id: 1, name: 'company', parent: null, children: [
          {id: 2, parent: {id: 1}, name: 'subcompany1', children: [
              




            {id: 4, parent: {id: 3}, name: 'example-company', children: [
                {id: 4, parent: {id: 3}, name: 'example-company', children: [
                    {id: 4, parent: {id: 3}, name: 'example-company', children: [
                        {id: 4, parent: {id: 3}, name: 'example-company', children: [
                            {id: 4, parent: {id: 3}, name: 'example-company', children: [
                                {id: 4, parent: {id: 3}, name: 'example-company', children: []},
                                {id: 4, parent: {id: 3}, name: 'example-company', children: []}
                              ]},
                            {id: 4, parent: {id: 3}, name: 'example-company', children: []}
                          ]},
                        {id: 4, parent: {id: 3}, name: 'example-company', children: []}
                      ]},
                    {id: 4, parent: {id: 3}, name: 'example-company', children: []}
                  ]},
                {id: 4, parent: {id: 3}, name: 'example-company', children: []}
              ]},
            {id: 4, parent: {id: 3}, name: 'example-company', children: []}





          ]},
          {id: 3, parent: {id: 1}, name: 'subcompany2', children: [
            {id: 4, parent: {id: 3}, name: 'example-company', children: [
                {id: 4, parent: {id: 3}, name: 'example-company', children: [
                    {id: 4, parent: {id: 3}, name: 'example-company', children: [
                        {id: 4, parent: {id: 3}, name: 'example-company', children: [
                            {id: 4, parent: {id: 3}, name: 'example-company', children: [
                                {id: 4, parent: {id: 3}, name: 'example-company', children: []},
                                {id: 4, parent: {id: 3}, name: 'example-company', children: []}
                              ]},
                            {id: 4, parent: {id: 3}, name: 'example-company', children: []}
                          ]},
                        {id: 4, parent: {id: 3}, name: 'example-company', children: []}
                      ]},
                    {id: 4, parent: {id: 3}, name: 'example-company', children: []}
                  ]},
                {id: 4, parent: {id: 3}, name: 'example-company', children: []}
              ]},
            {id: 4, parent: {id: 3}, name: 'example-company', children: []}
          ]}
        ]} */
  //    ]

    //  var newUserArray = data;
  /* var newUserArray = [];

      if(sponsorships && partners) {

      let jsonArr = [sponsorships[0]]
      newUserArray = jsonArr.map(
        
          obj => {
              return {
                  "id" : obj._id,
                  "parent":null,
                  "name":obj.username,
                  "parent_username":obj.parent_username,
                  "first_name":obj.first_name,
                  "last_name":obj.last_name,
                  "country":obj.country,
                  "status":obj.status,
                  "verticalLevel":obj.verticalLevel,
                  "horizontalLevel":obj.horizontalLevel,
                  "children":partners
              }
          }
        );
      }*/

   /*   var newUserArray = [];
    
          if(all_users && admin_partners.length > 0 ) {
            newUserArray = admin_partners
          }
          */

  /*      
<Tree data={newUserArray}
direction 
onClick={item => onClickItem(item.id) }/> */











const showTableActivity =
      
<Fragment>
  <br/>
      <p className='lead'>
        <i className='fas fa-user' /> {t('AdminSponsorship.Current_balance') + " : "}
        <strong style={{fontSize: "30px"}}> {transactions && transactions.length > 0 && transactions[0].availability === 0 ? t('AdminSponsorship.Monthly_commissions') + " ..." : selectedUser ? all_users[selectedUser.new_id - 1].wallet.toFixed(2) + "€" : user.wallet.toFixed(2) + "€"}</strong>
      </p>
  
<h1 className='large text-primary'>{t('AdminSponsorship.Activities')}</h1>



<div
style={{
display: "flex",
justifyContent: "center",
alignItems: "center"
}}>

<div className="scrollme"> 




    
<table id="tbl"className={`table table-bordered  table-hover table-striped " ${theme === "light" ? "" : "table-dark"}`}>
<thead  className={theme === "light" ? "thead-dark" : "thead-light"} >
<tr>
  <th scope="col">{t('AdminSponsorship.Ref')}</th>
  <th scope="col">{t('AdminSponsorship.Date')}</th>
  <th scope="col">{t('AdminSponsorship.Description')}</th>
  <th scope="col">{t('AdminSponsorship.Amount')}</th>
  <th scope="col">{t('AdminSponsorship.Balance')}</th>
  <th scope="col">{t('AdminSponsorship.Action')}</th>
</tr>
</thead>
<tbody>


{!transactions ? 
      <tr>
      <th colSpan={6}>{t('AdminSponsorship.Calculating') + " ..."}</th>
    </tr>
  :
  transactions.length === 0 ?
  <tr>
      <th colSpan={6}>{t('AdminSponsorship.No_activities')}</th>
    </tr>
   :
   currentTransactions.map(( get_transaction, index ) => {
// console.log("sponsorships"+JSON.stringify(sponsorship))
var date = get_transaction.date_creation.substring(0, 10).split("-")
var finalDate = date[1] + '/' + date[2] + '/' + date[0];
  if (t('Language.Lang') === "Français" || t('Language.Lang') === "Italiano") {
    finalDate = date[2] + '/' + date[1] + '/' + date[0];
  }
 return (
  get_transaction.availability === 1 ?
<tr key={index}>
  <td>{get_transaction.new_id}</td>
    <td>{finalDate}</td>
  <td>{t(`Server.${get_transaction.title}`) + (/*get_transaction.title === "server_12_transaction" ? " " + --penalityCounter : */get_transaction.transaction_rib !== "" ? " " + t('Server.server_21_transaction') + " " + get_transaction.transaction_rib : get_transaction.transaction_username !== "" ? " " + t('Server.server_13_transaction') + " " + get_transaction.transaction_username : "") + ((get_transaction.transaction_type === 4 || get_transaction.transaction_type === 5) ? " - (" + t('Server.server_22_transaction') + " ...)" : ((get_transaction.transaction_type === 8 || get_transaction.transaction_type === 10) ? " - (" + t('Server.server_23_transaction') + ")" : ""))}</td>
  <td><font color={/*#03925e*/get_transaction.amount >= 0 ? "" : "red"}>{get_transaction.amount.toFixed(2) + "€"}</font></td>
  <td>{get_transaction.balance.toFixed(2) + "€"}</td>
  <td>
    {get_transaction.transaction_type === 0 || get_transaction.transaction_type === 2 || get_transaction.transaction_type === 3 || get_transaction.transaction_type === 13 ?
<button type="button" className="btn btn-primary" onClick={() => getInvoice(get_transaction.user, get_transaction.username, get_transaction, t('Language.Lang'),t(`Country.${get_transaction.country}`))}>{t('DigitalWallet.Consult_invoice')}</button>
    :
    get_transaction.transaction_type === 1 ?
<button type="button" className="btn btn-primary" onClick={() => getInvoice(get_transaction.user, get_transaction.username, get_transaction, t('Language.Lang'),t(`Country.${get_transaction.country}`))}>{t('DigitalWallet.Consult_file')}</button>
   :
   get_transaction.transaction_type === 8 ?
   <button type="button" className="btn btn-primary" onClick={() => getInvoice(get_transaction.user, get_transaction.username, get_transaction, t('Language.Lang'),t(`Country.${get_transaction.country}`))}>{t('DigitalWallet.Consult_receipt')}</button>
   :
   t('DigitalWallet.No_action')
  //  getInvoice(selectedUser._id, selectedUser.name, get_transaction, t('Language.Lang'),t(`Country.${selectedUser.country}`))}>{t('DigitalWallet.Download_invoice')
  }
  </td>
</tr>
:
<tr key={index}>
  <td>{get_transaction.new_id}</td>
    <td>{finalDate}</td>
  <td>{t(`Server.${get_transaction.title}`) + (/*get_transaction.title === "server_12_transaction" ? " " + --penalityCounter : */get_transaction.transaction_rib !== "" ? " " + t('Server.server_21_transaction') + " " + get_transaction.transaction_rib : get_transaction.transaction_username !== "" ? " " + t('Server.server_13_transaction') + " " + get_transaction.transaction_username : "") + ((get_transaction.transaction_type === 4 || get_transaction.transaction_type === 5) ? " - (" + t('Server.server_22_transaction') + " ...)" : ((get_transaction.transaction_type === 8 || get_transaction.transaction_type === 10) ? " - (" + t('Server.server_23_transaction') + ")" : ""))}</td>
  <td>{t('AdminSponsorship.Calculating') + " ..."}</td>
  <td>{t('AdminSponsorship.Calculating') + " ..."}</td>
  <td>{t('AdminSponsorship.Calculating') + " ..."}</td>
</tr>
);
})}

</tbody>
</table>
</div>
</div>



<div
 style={{
   display: "flex",
   justifyContent: "center",
   alignItems: "center"
 }}>
<div className="scrollme"> 
<table>
{ transactions && transactions.length > 0 ? <Pagination usersPerPage={transactionsPerPage} totalUsers={transactions && transactions.length > 0 ? transactions.length : 0} paginate={paginateTransaction} nextPage={nextPageTransaction} prevPage={prevPageTransaction} firstPage={firstPageTransaction} lastPage={lastPageTransaction} currentPage={currentPageTransaction} /> : null }
</table>
</div>
</div>

</Fragment>;





















      
      const showTable =
      
      
      <Fragment>
      
      
<h1 className='large text-primary'>{t('AdminSponsorship.Subscriber_list')}</h1>


  
<div
 style={{
   display: "flex",
   justifyContent: "center",
   alignItems: "center"
 }}>

<div className="scrollme"> 


<div className='form'>
<div className="form-group">
          <input
            type='text'
            placeholder={t('AdminSponsorship.Search_by') + " " + (searchCriteria === "S" ? t('AdminSponsorship.Subscriber').toLowerCase() : searchCriteria === "G" ? t('AdminSponsorship.Guarantor').toLowerCase() : searchCriteria === "C" ? t('AdminSponsorship.Country').toLowerCase() : searchCriteria === "P" ? t('AdminSponsorship.Position').toLowerCase() : searchCriteria === "R" ? t('AdminSponsorship.Reference').toLowerCase() : searchCriteria === "D" ? t('AdminSponsorship.Day_text').toLowerCase() : searchCriteria === "M" ? t('AdminSponsorship.Month_text').toLowerCase() : searchCriteria === "Y" ? t('AdminSponsorship.Year_text').toLowerCase() : "")}
            name='searchField'
            value={searchField}
            onChange={e => findByCriteria(e.target.value)}
          />
        </div>
</div>


<div className="form-group" style={{
   display: "flex",
   alignItems: "center" }}>
         <h4>
    {t('AdminSponsorship.Search_by')}:
          </h4>
        <label style={{marginRight:"20px", marginTop: "10px"}}>

      </label>


      <div className="custom-control custom-radio custom-control-inline">
        <input type="radio" value="R" 
         id="ref"
         className="custom-control-input"
                      checked={searchCriteria === 'R'} 
                      onChange={handleSearchCriteriaChange} />
                      <label className="custom-control-label" for="ref">
          {t('AdminSponsorship.Ref')}
      </label>
      </div>

      <div className="custom-control custom-radio custom-control-inline">
        <input type="radio" value="S" 
         id="subscriber"
         className="custom-control-input"
                      checked={searchCriteria === 'S'} 
                      onChange={handleSearchCriteriaChange} />
                      <label className="custom-control-label" for="subscriber">
          {t('AdminSponsorship.Subscriber')}
      </label>
      </div>

      <div className="custom-control custom-radio custom-control-inline">
        <input type="radio" value="P" 
         id="position"
         className="custom-control-input"
                      checked={searchCriteria === 'P'} 
                      onChange={handleSearchCriteriaChange} />
                      <label className="custom-control-label" for="position">
          {t('AdminSponsorship.Position')}
      </label>
      </div>

      <div className="custom-control custom-radio custom-control-inline">
        <input type="radio" value="G" 
         id="guarantor"
         className="custom-control-input"
                      checked={searchCriteria === 'G'} 
                      onChange={handleSearchCriteriaChange} />
                      <label className="custom-control-label" for="guarantor">
          {t('AdminSponsorship.Guarantor')}
      </label>
      </div>

      <div className="custom-control custom-radio custom-control-inline">
        <input type="radio" value="C" 
         id="country"
         className="custom-control-input"
                      checked={searchCriteria === 'C'} 
                      onChange={handleSearchCriteriaChange} />
                      <label className="custom-control-label" for="country">
          {t('AdminSponsorship.Country')}
      </label>
      </div>


      <div className="custom-control custom-radio custom-control-inline">
        <input type="radio" value="D" 
         id="day"
         className="custom-control-input"
                      checked={searchCriteria === 'D'} 
                      onChange={handleSearchCriteriaChange} />
                      <label className="custom-control-label" for="day">
          {t('AdminSponsorship.Day')}
      </label>
      </div>

      <div className="custom-control custom-radio custom-control-inline">
        <input type="radio" value="M" 
         id="month"
         className="custom-control-input"
                      checked={searchCriteria === 'M'} 
                      onChange={handleSearchCriteriaChange} />
                      <label className="custom-control-label" for="month">
          {t('AdminSponsorship.Month')}
      </label>
      </div>


      <div className="custom-control custom-radio custom-control-inline">
        <input type="radio" value="Y" 
         id="year"
         className="custom-control-input"
                      checked={searchCriteria === 'Y'} 
                      onChange={handleSearchCriteriaChange} />
                      <label className="custom-control-label" for="year">
          {t('AdminSponsorship.Year')}
      </label>
      </div>

    </div>

<div className="form-group" style={{
   display: "flex",
   alignItems: "center" }}>
         <h4>
    {t('AdminSponsorship.Status')}:
          </h4>
        <label style={{marginRight:"20px", marginTop: "10px"}}>

      </label>

      <div className="custom-control custom-radio custom-control-inline">
        <input type="radio" value="A" 
         id="all"
         className="custom-control-input"
                      checked={criteria === 'A'} 
                      onChange={handleCriteriaChange} />
                      <label className="custom-control-label" for="all">
          {t('AdminSponsorship.All')}
      </label>
      </div>

      <div className="custom-control custom-radio custom-control-inline">
        <input type="radio" value="C" 
         id="candidate"
         className="custom-control-input"
                      checked={criteria === 'C'} 
                      onChange={handleCriteriaChange} />
                      <label className="custom-control-label" for="candidate">
          {t('AdminSponsorship.Candidate')}
      </label>
      </div>


      <div className="custom-control custom-radio custom-control-inline">
        <input type="radio" value="AM" 
         id="member"
         className="custom-control-input"
                      checked={criteria === 'AM'} 
                      onChange={handleCriteriaChange} />
                      <label className="custom-control-label" for="member">
          {t('AdminSponsorship.Member')}
      </label>
      </div>


      <div className="custom-control custom-radio custom-control-inline">
        <input type="radio" value="B" 
         id="candidate_member"
         className="custom-control-input"
                      checked={criteria === 'B'} 
                      onChange={handleCriteriaChange} />
                      <label className="custom-control-label" for="candidate_member">
          {t('AdminSponsorship.Candidate_member')}
      </label>
      </div>
      

      <div className="custom-control custom-radio custom-control-inline">
        <input type="radio" value="M" 
         id="actif_member"
         className="custom-control-input"
                      checked={criteria === 'M'} 
                      onChange={handleCriteriaChange} />
                      <label className="custom-control-label" for="actif_member">
          {t('AdminSponsorship.Actif_member')}
      </label>
      </div>


      <div className="custom-control custom-radio custom-control-inline">
        <input type="radio" value="R" 
         id="repositioned"
         className="custom-control-input"
                      checked={criteria === 'R'} 
                      onChange={handleCriteriaChange} />
                      <label className="custom-control-label" for="repositioned">
          {t('AdminSponsorship.Repositioned')}
      </label>
      </div>


      <div className="custom-control custom-radio custom-control-inline">
        <input type="radio" value="J" 
         id="joker"
         className="custom-control-input"
                      checked={criteria === 'J'} 
                      onChange={handleCriteriaChange} />
                      <label className="custom-control-label" for="joker">
          {t('AdminSponsorship.Joker')}
      </label>
      </div>
      

      <div className="custom-control custom-radio custom-control-inline">
        <input type="radio" value="RD" 
         id="removed"
         className="custom-control-input"
                      checked={criteria === 'RD'} 
                      onChange={handleCriteriaChange} />
                      <label className="custom-control-label" for="removed">
          {t('AdminSponsorship.Removed')}
      </label>
      </div>

      <div className="custom-control custom-radio custom-control-inline">
        <input type="radio" value="D" 
         id="deleted"
         className="custom-control-input"
                      checked={criteria === 'D'} 
                      onChange={handleCriteriaChange} />
                      <label className="custom-control-label" for="deleted">
          {t('AdminSponsorship.Deleted')}
      </label>
      </div>


    </div>

    <div className="form-group" style={{
   display: "flex",
   alignItems: "center" }}>
         <h4>
    {t('AdminSponsorship.Other_criteria')}:
          </h4>
        <label style={{marginRight:"20px", marginTop: "10px"}}>

      </label>


      <div className="custom-control custom-checkbox custom-control-inline">
        <input type="checkbox"
         id="unpaid"
         className="custom-control-input"
                      checked={unpaidChange === true} 
                      onChange={handleUnpaidChange} />
                      <label className="custom-control-label" for="unpaid">
          {t('AdminSponsorship.Unpaid')}
      </label>
      </div>

      <div className="custom-control custom-checkbox custom-control-inline">
        <input type="checkbox"
         id="penalized"
         className="custom-control-input"
                      checked={penaltyChange === true} 
                      onChange={handlePenaltyChange} />
                      <label className="custom-control-label" for="penalized">
          {t('AdminSponsorship.Penalized')}
      </label>
      </div>


      <div className="custom-control custom-checkbox custom-control-inline">
        <input type="checkbox"
         id="old_account"
         className="custom-control-input"
                      checked={oldAccountChange === true} 
                      onChange={handleOldAccountChange} />
                      <label className="custom-control-label" for="old_account">
          {t('AdminSponsorship.Old_account')}
      </label>
      </div>


      <div className="custom-control custom-checkbox custom-control-inline">
        <input type="checkbox"
         id="male"
         className="custom-control-input"
                      checked={genderMaleChange === true} 
                      onChange={handleGenderMaleChange} />
                      <label className="custom-control-label" for="male">
          {t('AdminSponsorship.Male')}
      </label>
      </div>



      <div className="custom-control custom-checkbox custom-control-inline">
        <input type="checkbox"
         id="female"
         className="custom-control-input"
                      checked={genderFemaleChange === true} 
                      onChange={handleGenderFemaleChange} />
                      <label className="custom-control-label" for="female">
          {t('AdminSponsorship.Female')}
      </label>
      </div>





    </div>
        

<table id="tbl" className={`table table-bordered  table-hover table-striped " ${theme === "light" ? "" : "table-dark"}`}>
  <thead  className={theme === "light" ? "thead-dark" : "thead-light"} >
  <tr>
      <th scope="col">{t('AdminSponsorship.Num')}</th>
      <th scope="col">{t('AdminSponsorship.Date')}</th>
      <th scope="col">{t('AdminSponsorship.Identity')}</th>
      <th scope="col">{t('AdminSponsorship.Subscriber')}</th>
      <th scope="col">{t('AdminSponsorship.Status')}</th>
      <th scope="col">{t('AdminSponsorship.Position')}</th>
      <th scope="col">{t('AdminSponsorship.Guarantor')}</th>
      <th scope="col">{t('AdminSponsorship.Country')}</th>
      <th scope="col">{t('AdminSponsorship.Payments')}</th>
      <th scope="col">{t('AdminSponsorship.Actions')}</th>
    </tr>
  </thead>
  <tbody>

  {!all_users ? 
          <tr>
          <th colSpan={9}>{t('AdminSponsorship.Calculating') + " ..."}</th>
        </tr>
      :
      sortedUsers.length === 0 ?
      <tr>
          <th colSpan={9}>{t('AdminSponsorship.No_subscribers')}</th>
        </tr>
       :
      currentUsers.map(( get_user, index ) => {
   // console.log("sponsorships"+JSON.stringify(sponsorship))
     return (
    <tr key={index}>
      <td>{/*get_user.new_id*/ sortedUsers.length - get_user.number + 1 }</td>
    <td>{(t('Language.Lang') === "Français" || t('Language.Lang') === "Italiano") ? get_user.date_creation ? get_user.date_creation.substring(0, 10).split("-")[2] + '/' + get_user.date_creation.substring(0, 10).split("-")[1] + '/' + get_user.date_creation.substring(0, 10).split("-")[0] : get_user.date_repositioning.substring(0, 10).split("-")[2] + '/' + get_user.date_repositioning.substring(0, 10).split("-")[1] + '/' + get_user.date_repositioning.substring(0, 10).split("-")[0] : get_user.date_creation ? get_user.date_creation.substring(0, 10).split("-")[1] + '/' + get_user.date_creation.substring(0, 10).split("-")[2] + '/' + get_user.date_creation.substring(0, 10).split("-")[0] : get_user.date_repositioning.substring(0, 10).split("-")[1] + '/' + get_user.date_repositioning.substring(0, 10).split("-")[2] + '/' + get_user.date_repositioning.substring(0, 10).split("-")[0]}</td>
    <td><div style={{width: 50, height: 'auto', display: get_user.attachment_name_front.split('.').pop() === "pdf" ? "block" : "none"}}>
 <a href={get_user.attachment_name_front} target="_blank"  rel="noopener noreferrer"><img border="0" alt="pdf" src={pdfIcon} /></a></div>
 <a href={get_user.attachment_name_front} target="_blank"  rel="noopener noreferrer"><img src={get_user.attachment_name_front} alt="" className="rounded-lg" style = {{ display: get_user.attachment_name_front.split('.').pop() !== "pdf" ? 'block' : "none",
  maxWidth:'75px',
  maxHeight:'75px',
  width: 'auto',
  height: 'auto'}}/></a></td>
      <td>{get_user.username}</td>
      <td>
      {(get_user.status === "C" ? t('AdminSponsorship.Candidate') :
      get_user.status === "D" && get_user.deletion_cause === "Bad_behaviour" ? t('AdminSponsorship.Removed') :
      get_user.status === "D" ? t('AdminSponsorship.Deleted') :
      get_user.status === "J" ? t('AdminSponsorship.Joker') :
      get_user.status === "A" ? t('AdminSponsorship.Member') :
      get_user.status === "B" ? t('AdminSponsorship.Candidate_member') :
      get_user.status.charAt(0) === "M" ? t('AdminSponsorship.Actif_member') + get_user.status.substring(1) : "" )}
      </td>
      <td>{get_user.horizontalLevel > 0 ? (get_user.verticalLevel + "-" + get_user.horizontalLevel) : t('AdminSponsorship.None_f')}</td>
      <td>{get_user.parent_username !== '' ?
     get_user.parent_username : t('AdminSponsorship.None')}</td>
      <td>{t(`Country.${get_user.country}`)}</td>

      <td>
        {get_user.payed_status === 1 && get_user.status !== "D"? 
             <button type="button" className="btn btn-primary" onClick={() => onClickPaymentItem(get_user)}>{t('AdminSponsorship.Pending_invoice')}</button> :
             get_user.payed_status === 2 && get_user.status !== "D" ? 
             <button type="button" className="btn btn-primary" onClick={() => onClickPaymentItem(get_user)}>{t('AdminSponsorship.Validate_payment')}</button> :
             get_user.payed_status >= 3 && get_user.status !== "D" ? 
             t('AdminSponsorship.Paid') :
             
             t('AdminSponsorship.Unpaid')}
           </td>

      <td>
        {  
        (get_user.status === "C" || get_user.status === "A") ? 
        <select className="form-control" name="action" value={""} onChange={e => dropDownSelected(e.target.value)}>
        <option value="">{t('AdminSponsorship.Select_action')}</option>
        <option value= {'{"index": "1", "user": {"_id": "' + get_user._id + '", "new_id": "' + get_user.new_id + '", "name": "' + get_user.username + '", "first_name": "' + get_user.first_name + '", "last_name": "' + get_user.last_name + '", "status": "' + get_user.status + '", "deletion_cause": "' + get_user.deletion_cause + '", "parent_username": "' + get_user.parent_username + '", "parent_id": "' + get_user.parent_id + '", "country": "' + get_user.country + '", "phone_number": "' + get_user.phone_number+ '", "address": "' + get_user.address + '", "additional_address": "' + get_user.additional_address + '", "city": "' + get_user.city + '", "postal_code": "' + get_user.postal_code + '", "attachment_type": "' + get_user.attachment_type + '", "attachment_name_front": "' + get_user.attachment_name_front + '", "attachment_name_back": "' + get_user.attachment_name_back + '", "verticalLevel": "' + get_user.verticalLevel + '", "horizontalLevel": "' + get_user.horizontalLevel + '", "gender": "' + get_user.gender + '", "date_of_birth": "' + get_user.date_of_birth + '", "email": "' + get_user.email + '", "date_creation": "' + get_user.date_creation + '", "date_member": "' + get_user.date_member + '", "date_repositioning": "' + get_user.date_repositioning + '", "confirmed": "' + get_user.confirmed + '", "penalty": "' + get_user.penalty + '", "already_repositioned": "' + get_user.already_repositioned +'"}}'}>{t('AdminSponsorship.History')}</option>
        <option disabled>──────────</option>
        <option value= {'{"index": "6", "user": {"_id": "' + get_user._id + '", "new_id": "' + get_user.new_id + '", "name": "' + get_user.username + '", "first_name": "' + get_user.first_name + '", "last_name": "' + get_user.last_name + '", "status": "' + get_user.status + '", "deletion_cause": "' + get_user.deletion_cause + '", "parent_username": "' + get_user.parent_username + '", "parent_id": "' + get_user.parent_id + '", "country": "' + get_user.country + '", "phone_number": "' + get_user.phone_number+ '", "address": "' + get_user.address + '", "additional_address": "' + get_user.additional_address + '", "city": "' + get_user.city + '", "postal_code": "' + get_user.postal_code + '", "attachment_type": "' + get_user.attachment_type + '", "attachment_name_front": "' + get_user.attachment_name_front + '", "attachment_name_back": "' + get_user.attachment_name_back + '", "verticalLevel": "' + get_user.verticalLevel + '", "horizontalLevel": "' + get_user.horizontalLevel + '", "gender": "' + get_user.gender + '", "date_of_birth": "' + get_user.date_of_birth + '", "email": "' + get_user.email +'"}}'}>{t('AdminSponsorship.Delete')}</option>
      </select>
      : 
        get_user.status === "B" ? 
             <select className="form-control" name="action" value={""} onChange={e => dropDownSelected(e.target.value)}>
             <option value="">{t('AdminSponsorship.Select_action')}</option>
             <option value= {'{"index": "1", "user": {"_id": "' + get_user._id + '", "new_id": "' + get_user.new_id + '", "name": "' + get_user.username + '", "first_name": "' + get_user.first_name + '", "last_name": "' + get_user.last_name + '", "status": "' + get_user.status + '", "deletion_cause": "' + get_user.deletion_cause + '", "parent_username": "' + get_user.parent_username + '", "parent_id": "' + get_user.parent_id + '", "country": "' + get_user.country + '", "phone_number": "' + get_user.phone_number+ '", "address": "' + get_user.address + '", "additional_address": "' + get_user.additional_address + '", "city": "' + get_user.city + '", "postal_code": "' + get_user.postal_code + '", "attachment_type": "' + get_user.attachment_type + '", "attachment_name_front": "' + get_user.attachment_name_front + '", "attachment_name_back": "' + get_user.attachment_name_back + '", "verticalLevel": "' + get_user.verticalLevel + '", "horizontalLevel": "' + get_user.horizontalLevel + '", "gender": "' + get_user.gender + '", "date_of_birth": "' + get_user.date_of_birth + '", "email": "' + get_user.email + '", "date_creation": "' + get_user.date_creation + '", "date_member": "' + get_user.date_member + '", "date_repositioning": "' + get_user.date_repositioning + '", "confirmed": "' + get_user.confirmed + '", "penalty": "' + get_user.penalty + '", "already_repositioned": "' + get_user.already_repositioned +'"}}'}>{t('AdminSponsorship.History')}</option>
             <option value= {/*'{"index": "2", "id": "' + get_user._id + '"}'*/'{"index": "2", "user": {"_id": "' + get_user._id + '", "new_id": "' + get_user.new_id + '", "name": "' + get_user.username + '", "first_name": "' + get_user.first_name + '", "last_name": "' + get_user.last_name + '", "status": "' + get_user.status + '", "deletion_cause": "' + get_user.deletion_cause + '", "parent_username": "' + get_user.parent_username + '", "parent_id": "' + get_user.parent_id + '", "country": "' + get_user.country + '", "phone_number": "' + get_user.phone_number+ '", "address": "' + get_user.address + '", "additional_address": "' + get_user.additional_address + '", "city": "' + get_user.city + '", "postal_code": "' + get_user.postal_code + '", "attachment_type": "' + get_user.attachment_type + '", "attachment_name_front": "' + get_user.attachment_name_front + '", "attachment_name_back": "' + get_user.attachment_name_back + '", "verticalLevel": "' + get_user.verticalLevel + '", "horizontalLevel": "' + get_user.horizontalLevel + '", "gender": "' + get_user.gender + '", "date_of_birth": "' + get_user.date_of_birth + '", "email": "' + get_user.email +'"}}'}>{t('AdminSponsorship.Matrix')}</option>
             <option value= {'{"index": "3", "user": {"_id": "' + get_user._id + '", "new_id": "' + get_user.new_id + '", "name": "' + get_user.username + '", "first_name": "' + get_user.first_name + '", "last_name": "' + get_user.last_name + '", "status": "' + get_user.status + '", "deletion_cause": "' + get_user.deletion_cause + '", "parent_username": "' + get_user.parent_username + '", "parent_id": "' + get_user.parent_id + '", "country": "' + get_user.country + '", "phone_number": "' + get_user.phone_number+ '", "address": "' + get_user.address + '", "additional_address": "' + get_user.additional_address + '", "city": "' + get_user.city + '", "postal_code": "' + get_user.postal_code + '", "attachment_type": "' + get_user.attachment_type + '", "attachment_name_front": "' + get_user.attachment_name_front + '", "attachment_name_back": "' + get_user.attachment_name_back + '", "verticalLevel": "' + get_user.verticalLevel + '", "horizontalLevel": "' + get_user.horizontalLevel + '", "gender": "' + get_user.gender + '", "date_of_birth": "' + get_user.date_of_birth + '", "date_creation": "' + get_user.date_creation + '", "date_member": "' + get_user.date_member + '", "date_repositioning": "' + get_user.date_repositioning + '", "confirmed": "' + get_user.confirmed + '", "email": "' + get_user.email +'"}}'}>{t('AdminSponsorship.Affiliation_tree')}</option>
             <option value= {'{"index": "4", "user": {"_id": "' + get_user._id + '", "new_id": "' + get_user.new_id + '", "name": "' + get_user.username + '", "first_name": "' + get_user.first_name + '", "last_name": "' + get_user.last_name + '", "status": "' + get_user.status + '", "deletion_cause": "' + get_user.deletion_cause + '", "parent_username": "' + get_user.parent_username + '", "parent_id": "' + get_user.parent_id + '", "country": "' + get_user.country + '", "phone_number": "' + get_user.phone_number+ '", "address": "' + get_user.address + '", "additional_address": "' + get_user.additional_address + '", "city": "' + get_user.city + '", "postal_code": "' + get_user.postal_code + '", "attachment_type": "' + get_user.attachment_type + '", "attachment_name_front": "' + get_user.attachment_name_front + '", "attachment_name_back": "' + get_user.attachment_name_back + '", "verticalLevel": "' + get_user.verticalLevel + '", "horizontalLevel": "' + get_user.horizontalLevel + '", "gender": "' + get_user.gender + '", "date_of_birth": "' + get_user.date_of_birth + '", "email": "' + get_user.email +'"}}'}>{t('AdminSponsorship.Payment_table')}</option>
             <option value= {'{"index": "5", "user": {"_id": "' + get_user._id + '", "new_id": "' + get_user.new_id + '", "name": "' + get_user.username + '", "first_name": "' + get_user.first_name + '", "last_name": "' + get_user.last_name + '", "status": "' + get_user.status + '", "deletion_cause": "' + get_user.deletion_cause + '", "parent_username": "' + get_user.parent_username + '", "parent_id": "' + get_user.parent_id + '", "country": "' + get_user.country + '", "phone_number": "' + get_user.phone_number+ '", "address": "' + get_user.address + '", "additional_address": "' + get_user.additional_address + '", "city": "' + get_user.city + '", "postal_code": "' + get_user.postal_code + '", "attachment_type": "' + get_user.attachment_type + '", "attachment_name_front": "' + get_user.attachment_name_front + '", "attachment_name_back": "' + get_user.attachment_name_back + '", "verticalLevel": "' + get_user.verticalLevel + '", "horizontalLevel": "' + get_user.horizontalLevel + '", "gender": "' + get_user.gender + '", "date_of_birth": "' + get_user.date_of_birth + '", "wallet": "' + get_user.wallet + '", "email": "' + get_user.email +'"}}'}>{t('AdminSponsorship.Digital_wallet')}</option>
             <option disabled>──────────</option>
             <option value= {'{"index": "6", "user": {"_id": "' + get_user._id + '", "new_id": "' + get_user.new_id + '", "name": "' + get_user.username + '", "first_name": "' + get_user.first_name + '", "last_name": "' + get_user.last_name + '", "status": "' + get_user.status + '", "deletion_cause": "' + get_user.deletion_cause + '", "parent_username": "' + get_user.parent_username + '", "parent_id": "' + get_user.parent_id + '", "country": "' + get_user.country + '", "phone_number": "' + get_user.phone_number+ '", "address": "' + get_user.address + '", "additional_address": "' + get_user.additional_address + '", "city": "' + get_user.city + '", "postal_code": "' + get_user.postal_code + '", "attachment_type": "' + get_user.attachment_type + '", "attachment_name_front": "' + get_user.attachment_name_front + '", "attachment_name_back": "' + get_user.attachment_name_back + '", "verticalLevel": "' + get_user.verticalLevel + '", "horizontalLevel": "' + get_user.horizontalLevel + '", "gender": "' + get_user.gender + '", "date_of_birth": "' + get_user.date_of_birth + '", "email": "' + get_user.email +'"}}'}>{t('AdminSponsorship.Delete')}</option>
           </select>
           : 
           get_user.status.charAt(0) === "M" /*&& get_user.already_repositioned === 0*/ ?
           <select className="form-control" name="action" value={""} onChange={e => dropDownSelected(e.target.value)}>
             <option value="">{t('AdminSponsorship.Select_action')}</option>
             <option value= {'{"index": "1", "user": {"_id": "' + get_user._id + '", "new_id": "' + get_user.new_id + '", "name": "' + get_user.username + '", "first_name": "' + get_user.first_name + '", "last_name": "' + get_user.last_name + '", "status": "' + get_user.status + '", "deletion_cause": "' + get_user.deletion_cause + '", "parent_username": "' + get_user.parent_username + '", "parent_id": "' + get_user.parent_id + '", "country": "' + get_user.country + '", "phone_number": "' + get_user.phone_number+ '", "address": "' + get_user.address + '", "additional_address": "' + get_user.additional_address + '", "city": "' + get_user.city + '", "postal_code": "' + get_user.postal_code + '", "attachment_type": "' + get_user.attachment_type + '", "attachment_name_front": "' + get_user.attachment_name_front + '", "attachment_name_back": "' + get_user.attachment_name_back + '", "verticalLevel": "' + get_user.verticalLevel + '", "horizontalLevel": "' + get_user.horizontalLevel + '", "gender": "' + get_user.gender + '", "date_of_birth": "' + get_user.date_of_birth + '", "email": "' + get_user.email + '", "date_creation": "' + get_user.date_creation + '", "date_member": "' + get_user.date_member + '", "date_repositioning": "' + get_user.date_repositioning + '", "confirmed": "' + get_user.confirmed + '", "penalty": "' + get_user.penalty + '", "already_repositioned": "' + get_user.already_repositioned +'"}}'}>{t('AdminSponsorship.History')}</option>
             <option value= {/*'{"index": "2", "id": "' + get_user._id + '"}'*/'{"index": "2", "user": {"_id": "' + get_user._id + '", "new_id": "' + get_user.new_id + '", "name": "' + get_user.username + '", "first_name": "' + get_user.first_name + '", "last_name": "' + get_user.last_name + '", "status": "' + get_user.status + '", "deletion_cause": "' + get_user.deletion_cause + '", "parent_username": "' + get_user.parent_username + '", "parent_id": "' + get_user.parent_id + '", "country": "' + get_user.country + '", "phone_number": "' + get_user.phone_number+ '", "address": "' + get_user.address + '", "additional_address": "' + get_user.additional_address + '", "city": "' + get_user.city + '", "postal_code": "' + get_user.postal_code + '", "attachment_type": "' + get_user.attachment_type + '", "attachment_name_front": "' + get_user.attachment_name_front + '", "attachment_name_back": "' + get_user.attachment_name_back + '", "verticalLevel": "' + get_user.verticalLevel + '", "horizontalLevel": "' + get_user.horizontalLevel + '", "gender": "' + get_user.gender + '", "date_of_birth": "' + get_user.date_of_birth + '", "email": "' + get_user.email +'"}}'}>{t('AdminSponsorship.Matrix')}</option>
             <option value= {'{"index": "3", "user": {"_id": "' + get_user._id + '", "new_id": "' + get_user.new_id + '", "name": "' + get_user.username + '", "first_name": "' + get_user.first_name + '", "last_name": "' + get_user.last_name + '", "status": "' + get_user.status + '", "deletion_cause": "' + get_user.deletion_cause + '", "parent_username": "' + get_user.parent_username + '", "parent_id": "' + get_user.parent_id + '", "country": "' + get_user.country + '", "phone_number": "' + get_user.phone_number+ '", "address": "' + get_user.address + '", "additional_address": "' + get_user.additional_address + '", "city": "' + get_user.city + '", "postal_code": "' + get_user.postal_code + '", "attachment_type": "' + get_user.attachment_type + '", "attachment_name_front": "' + get_user.attachment_name_front + '", "attachment_name_back": "' + get_user.attachment_name_back + '", "verticalLevel": "' + get_user.verticalLevel + '", "horizontalLevel": "' + get_user.horizontalLevel + '", "gender": "' + get_user.gender + '", "date_of_birth": "' + get_user.date_of_birth + '", "date_creation": "' + get_user.date_creation + '", "date_member": "' + get_user.date_member + '", "date_repositioning": "' + get_user.date_repositioning + '", "confirmed": "' + get_user.confirmed + '", "email": "' + get_user.email +'"}}'}>{t('AdminSponsorship.Affiliation_tree')}</option>
             <option value= {'{"index": "4", "user": {"_id": "' + get_user._id + '", "new_id": "' + get_user.new_id + '", "name": "' + get_user.username + '", "first_name": "' + get_user.first_name + '", "last_name": "' + get_user.last_name + '", "status": "' + get_user.status + '", "deletion_cause": "' + get_user.deletion_cause + '", "parent_username": "' + get_user.parent_username + '", "parent_id": "' + get_user.parent_id + '", "country": "' + get_user.country + '", "phone_number": "' + get_user.phone_number+ '", "address": "' + get_user.address + '", "additional_address": "' + get_user.additional_address + '", "city": "' + get_user.city + '", "postal_code": "' + get_user.postal_code + '", "attachment_type": "' + get_user.attachment_type + '", "attachment_name_front": "' + get_user.attachment_name_front + '", "attachment_name_back": "' + get_user.attachment_name_back + '", "verticalLevel": "' + get_user.verticalLevel + '", "horizontalLevel": "' + get_user.horizontalLevel + '", "gender": "' + get_user.gender + '", "date_of_birth": "' + get_user.date_of_birth + '", "email": "' + get_user.email +'"}}'}>{t('AdminSponsorship.Payment_table')}</option>
             <option value= {'{"index": "5", "user": {"_id": "' + get_user._id + '", "new_id": "' + get_user.new_id + '", "name": "' + get_user.username + '", "first_name": "' + get_user.first_name + '", "last_name": "' + get_user.last_name + '", "status": "' + get_user.status + '", "deletion_cause": "' + get_user.deletion_cause + '", "parent_username": "' + get_user.parent_username + '", "parent_id": "' + get_user.parent_id + '", "country": "' + get_user.country + '", "phone_number": "' + get_user.phone_number+ '", "address": "' + get_user.address + '", "additional_address": "' + get_user.additional_address + '", "city": "' + get_user.city + '", "postal_code": "' + get_user.postal_code + '", "attachment_type": "' + get_user.attachment_type + '", "attachment_name_front": "' + get_user.attachment_name_front + '", "attachment_name_back": "' + get_user.attachment_name_back + '", "verticalLevel": "' + get_user.verticalLevel + '", "horizontalLevel": "' + get_user.horizontalLevel + '", "gender": "' + get_user.gender + '", "date_of_birth": "' + get_user.date_of_birth + '", "wallet": "' + get_user.wallet + '", "email": "' + get_user.email +'"}}'}>{t('AdminSponsorship.Digital_wallet')}</option>
             <option disabled>──────────</option>
             <option value= {'{"index": "6", "user": {"_id": "' + get_user._id + '", "new_id": "' + get_user.new_id + '", "name": "' + get_user.username + '", "first_name": "' + get_user.first_name + '", "last_name": "' + get_user.last_name + '", "status": "' + get_user.status + '", "deletion_cause": "' + get_user.deletion_cause + '", "parent_username": "' + get_user.parent_username + '", "parent_id": "' + get_user.parent_id + '", "country": "' + get_user.country + '", "phone_number": "' + get_user.phone_number+ '", "address": "' + get_user.address + '", "additional_address": "' + get_user.additional_address + '", "city": "' + get_user.city + '", "postal_code": "' + get_user.postal_code + '", "attachment_type": "' + get_user.attachment_type + '", "attachment_name_front": "' + get_user.attachment_name_front + '", "attachment_name_back": "' + get_user.attachment_name_back + '", "verticalLevel": "' + get_user.verticalLevel + '", "horizontalLevel": "' + get_user.horizontalLevel + '", "gender": "' + get_user.gender + '", "date_of_birth": "' + get_user.date_of_birth + '", "email": "' + get_user.email +'"}}'}>{t('AdminSponsorship.Use_joker')}</option>
           </select>
          /* : 
           get_user.status.charAt(0) === "M" && get_user.already_repositioned === 1 ?
           <select name="action" value={""} onChange={e => dropDownSelected(e.target.value)}>
             <option value="">{t('AdminSponsorship.Select_action')}</option>
             <option value= {'{"index": "1", "user": {"_id": "' + get_user._id + '", "new_id": "' + get_user.new_id + '", "name": "' + get_user.username + '", "first_name": "' + get_user.first_name + '", "last_name": "' + get_user.last_name + '", "status": "' + get_user.status + '", "parent_username": "' + get_user.parent_username + '", "country": "' + get_user.country + '", "verticalLevel": "' + get_user.verticalLevel + '", "horizontalLevel": "' + get_user.horizontalLevel + '", "gender": "' + get_user.gender + '", "date_of_birth": "' + get_user.date_of_birth + '", "email": "' + get_user.email + '", "date_creation": "' + get_user.date_creation + '", "date_member": "' + get_user.date_member + '", "date_repositioning": "' + get_user.date_repositioning + '", "penalty": "' + get_user.penalty + '", "already_repositioned": "' + get_user.already_repositioned +'"}}'}>{t('AdminSponsorship.History')}</option>
             <option value= {/*'{"index": "2", "id": "' + get_user._id + '"}'*//*'{"index": "2", "user": {"_id": "' + get_user._id + '", "new_id": "' + get_user.new_id + '", "name": "' + get_user.username + '", "first_name": "' + get_user.first_name + '", "last_name": "' + get_user.last_name + '", "status": "' + get_user.status + '", "parent_username": "' + get_user.parent_username + '", "country": "' + get_user.country + '", "verticalLevel": "' + get_user.verticalLevel + '", "horizontalLevel": "' + get_user.horizontalLevel + '", "gender": "' + get_user.gender + '", "date_of_birth": "' + get_user.date_of_birth + '", "email": "' + get_user.email +'"}}'}>{t('AdminSponsorship.Matrix')}</option>
             <option value= {'{"index": "3", "user": {"_id": "' + get_user._id + '", "new_id": "' + get_user.new_id + '", "name": "' + get_user.username + '", "first_name": "' + get_user.first_name + '", "last_name": "' + get_user.last_name + '", "status": "' + get_user.status + '", "parent_username": "' + get_user.parent_username + '", "country": "' + get_user.country + '", "verticalLevel": "' + get_user.verticalLevel + '", "horizontalLevel": "' + get_user.horizontalLevel + '", "gender": "' + get_user.gender + '", "date_of_birth": "' + get_user.date_of_birth + '", "date_creation": "' + get_user.date_creation + '", "date_member": "' + get_user.date_member + '", "email": "' + get_user.email +'"}}'}>{t('AdminSponsorship.Affiliation_tree')}</option>
           </select>*/
           : 
           get_user.status === "J" ?
           <select className="form-control" name="action" value={""} onChange={e => dropDownSelected(e.target.value)}>
             <option value="">{t('AdminSponsorship.Select_action')}</option>
             <option value= {'{"index": "1", "user": {"_id": "' + get_user._id + '", "new_id": "' + get_user.new_id + '", "name": "' + get_user.username + '", "first_name": "' + get_user.first_name + '", "last_name": "' + get_user.last_name + '", "status": "' + get_user.status + '", "deletion_cause": "' + get_user.deletion_cause + '", "parent_username": "' + get_user.parent_username + '", "parent_id": "' + get_user.parent_id + '", "country": "' + get_user.country + '", "phone_number": "' + get_user.phone_number+ '", "address": "' + get_user.address + '", "additional_address": "' + get_user.additional_address + '", "city": "' + get_user.city + '", "postal_code": "' + get_user.postal_code + '", "attachment_type": "' + get_user.attachment_type + '", "attachment_name_front": "' + get_user.attachment_name_front + '", "attachment_name_back": "' + get_user.attachment_name_back + '", "verticalLevel": "' + get_user.verticalLevel + '", "horizontalLevel": "' + get_user.horizontalLevel + '", "gender": "' + get_user.gender + '", "date_of_birth": "' + get_user.date_of_birth + '", "email": "' + get_user.email + '", "date_creation": "' + get_user.date_creation + '", "date_member": "' + get_user.date_member + '", "date_repositioning": "' + get_user.date_repositioning + '", "confirmed": "' + get_user.confirmed + '", "penalty": "' + get_user.penalty + '", "already_repositioned": "' + get_user.already_repositioned +'"}}'}>{t('AdminSponsorship.History')}</option>
             <option value= {/*'{"index": "2", "id": "' + get_user._id + '"}'*/'{"index": "2", "user": {"_id": "' + get_user._id + '", "new_id": "' + get_user.new_id + '", "name": "' + get_user.username + '", "first_name": "' + get_user.first_name + '", "last_name": "' + get_user.last_name + '", "status": "' + get_user.status + '", "deletion_cause": "' + get_user.deletion_cause + '", "parent_username": "' + get_user.parent_username + '", "parent_id": "' + get_user.parent_id + '", "country": "' + get_user.country + '", "phone_number": "' + get_user.phone_number+ '", "address": "' + get_user.address + '", "additional_address": "' + get_user.additional_address + '", "city": "' + get_user.city + '", "postal_code": "' + get_user.postal_code + '", "attachment_type": "' + get_user.attachment_type + '", "attachment_name_front": "' + get_user.attachment_name_front + '", "attachment_name_back": "' + get_user.attachment_name_back + '", "verticalLevel": "' + get_user.verticalLevel + '", "horizontalLevel": "' + get_user.horizontalLevel + '", "gender": "' + get_user.gender + '", "date_of_birth": "' + get_user.date_of_birth + '", "email": "' + get_user.email +'"}}'}>{t('AdminSponsorship.Matrix')}</option>
             <option value= {'{"index": "3", "user": {"_id": "' + get_user._id + '", "new_id": "' + get_user.new_id + '", "name": "' + get_user.username + '", "first_name": "' + get_user.first_name + '", "last_name": "' + get_user.last_name + '", "status": "' + get_user.status + '", "deletion_cause": "' + get_user.deletion_cause + '", "parent_username": "' + get_user.parent_username + '", "parent_id": "' + get_user.parent_id + '", "country": "' + get_user.country + '", "phone_number": "' + get_user.phone_number+ '", "address": "' + get_user.address + '", "additional_address": "' + get_user.additional_address + '", "city": "' + get_user.city + '", "postal_code": "' + get_user.postal_code + '", "attachment_type": "' + get_user.attachment_type + '", "attachment_name_front": "' + get_user.attachment_name_front + '", "attachment_name_back": "' + get_user.attachment_name_back + '", "verticalLevel": "' + get_user.verticalLevel + '", "horizontalLevel": "' + get_user.horizontalLevel + '", "gender": "' + get_user.gender + '", "date_of_birth": "' + get_user.date_of_birth + '", "date_creation": "' + get_user.date_creation + '", "date_member": "' + get_user.date_member + '", "date_repositioning": "' + get_user.date_repositioning + '", "confirmed": "' + get_user.confirmed + '", "email": "' + get_user.email +'"}}'}>{t('AdminSponsorship.Affiliation_tree')}</option>
             <option value= {'{"index": "5", "user": {"_id": "' + get_user._id + '", "new_id": "' + get_user.new_id + '", "name": "' + get_user.username + '", "first_name": "' + get_user.first_name + '", "last_name": "' + get_user.last_name + '", "status": "' + get_user.status + '", "deletion_cause": "' + get_user.deletion_cause + '", "parent_username": "' + get_user.parent_username + '", "parent_id": "' + get_user.parent_id + '", "country": "' + get_user.country + '", "phone_number": "' + get_user.phone_number+ '", "address": "' + get_user.address + '", "additional_address": "' + get_user.additional_address + '", "city": "' + get_user.city + '", "postal_code": "' + get_user.postal_code + '", "attachment_type": "' + get_user.attachment_type + '", "attachment_name_front": "' + get_user.attachment_name_front + '", "attachment_name_back": "' + get_user.attachment_name_back + '", "verticalLevel": "' + get_user.verticalLevel + '", "horizontalLevel": "' + get_user.horizontalLevel + '", "gender": "' + get_user.gender + '", "date_of_birth": "' + get_user.date_of_birth + '", "wallet": "' + get_user.wallet + '", "email": "' + get_user.email +'"}}'}>{t('AdminSponsorship.Digital_wallet')}</option>
           </select>
           : get_user.status === "D" ?
          /* get_user.status === "K" ?
           <select className="form-control" name="action" value={""} onChange={e => dropDownSelected(e.target.value)}>
             <option value="">{t('AdminSponsorship.Select_action')}</option>
             <option value= {'{"index": "1", "user": {"_id": "' + get_user._id + '", "new_id": "' + get_user.new_id + '", "name": "' + get_user.username + '", "first_name": "' + get_user.first_name + '", "last_name": "' + get_user.last_name + '", "status": "' + get_user.status + '", "deletion_cause": "' + get_user.deletion_cause + '", "parent_username": "' + get_user.parent_username + '", "parent_id": "' + get_user.parent_id + '", "country": "' + get_user.country + '", "nationality": "' + get_user.nationality + '", "phone_number": "' + get_user.phone_number+ '", "address": "' + get_user.address + '", "additional_address": "' + get_user.additional_address + '", "city": "' + get_user.city + '", "postal_code": "' + get_user.postal_code + '", "attachment_type": "' + get_user.attachment_type + '", "attachment_name_front": "' + get_user.attachment_name_front + '", "attachment_name_back": "' + get_user.attachment_name_back + '", "verticalLevel": "' + get_user.verticalLevel + '", "horizontalLevel": "' + get_user.horizontalLevel + '", "gender": "' + get_user.gender + '", "date_of_birth": "' + get_user.date_of_birth + '", "email": "' + get_user.email + '", "date_creation": "' + get_user.date_creation + '", "date_member": "' + get_user.date_member + '", "date_repositioning": "' + get_user.date_repositioning + '", "confirmed": "' + get_user.confirmed + '", "penalty": "' + get_user.penalty + '", "already_repositioned": "' + get_user.already_repositioned +'"}}'}>{t('AdminSponsorship.History')}</option>
             <option value= {/*'{"index": "2", "id": "' + get_user._id + '"}'*//*'{"index": "2", "user": {"_id": "' + get_user._id + '", "new_id": "' + get_user.new_id + '", "name": "' + get_user.username + '", "first_name": "' + get_user.first_name + '", "last_name": "' + get_user.last_name + '", "status": "' + get_user.status + '", "deletion_cause": "' + get_user.deletion_cause + '", "parent_username": "' + get_user.parent_username + '", "parent_id": "' + get_user.parent_id + '", "country": "' + get_user.country + '", "nationality": "' + get_user.nationality + '", "phone_number": "' + get_user.phone_number+ '", "address": "' + get_user.address + '", "additional_address": "' + get_user.additional_address + '", "city": "' + get_user.city + '", "postal_code": "' + get_user.postal_code + '", "attachment_type": "' + get_user.attachment_type + '", "attachment_name_front": "' + get_user.attachment_name_front + '", "attachment_name_back": "' + get_user.attachment_name_back + '", "verticalLevel": "' + get_user.verticalLevel + '", "horizontalLevel": "' + get_user.horizontalLevel + '", "gender": "' + get_user.gender + '", "date_of_birth": "' + get_user.date_of_birth + '", "email": "' + get_user.email +'"}}'}>{t('AdminSponsorship.Matrix')}</option>
             <option value= {'{"index": "3", "user": {"_id": "' + get_user._id + '", "new_id": "' + get_user.new_id + '", "name": "' + get_user.username + '", "first_name": "' + get_user.first_name + '", "last_name": "' + get_user.last_name + '", "status": "' + get_user.status + '", "deletion_cause": "' + get_user.deletion_cause + '", "parent_username": "' + get_user.parent_username + '", "parent_id": "' + get_user.parent_id + '", "country": "' + get_user.country + '", "nationality": "' + get_user.nationality + '", "phone_number": "' + get_user.phone_number+ '", "address": "' + get_user.address + '", "additional_address": "' + get_user.additional_address + '", "city": "' + get_user.city + '", "postal_code": "' + get_user.postal_code + '", "attachment_type": "' + get_user.attachment_type + '", "attachment_name_front": "' + get_user.attachment_name_front + '", "attachment_name_back": "' + get_user.attachment_name_back + '", "verticalLevel": "' + get_user.verticalLevel + '", "horizontalLevel": "' + get_user.horizontalLevel + '", "gender": "' + get_user.gender + '", "date_of_birth": "' + get_user.date_of_birth + '", "date_creation": "' + get_user.date_creation + '", "date_member": "' + get_user.date_member + '", "date_repositioning": "' + get_user.date_repositioning + '", "confirmed": "' + get_user.confirmed + '", "email": "' + get_user.email +'"}}'}>{t('AdminSponsorship.Affiliation_tree')}</option>
             <option value= {'{"index": "5", "user": {"_id": "' + get_user._id + '", "new_id": "' + get_user.new_id + '", "name": "' + get_user.username + '", "first_name": "' + get_user.first_name + '", "last_name": "' + get_user.last_name + '", "status": "' + get_user.status + '", "deletion_cause": "' + get_user.deletion_cause + '", "parent_username": "' + get_user.parent_username + '", "parent_id": "' + get_user.parent_id + '", "country": "' + get_user.country + '", "nationality": "' + get_user.nationality + '", "phone_number": "' + get_user.phone_number+ '", "address": "' + get_user.address + '", "additional_address": "' + get_user.additional_address + '", "city": "' + get_user.city + '", "postal_code": "' + get_user.postal_code + '", "attachment_type": "' + get_user.attachment_type + '", "attachment_name_front": "' + get_user.attachment_name_front + '", "attachment_name_back": "' + get_user.attachment_name_back + '", "verticalLevel": "' + get_user.verticalLevel + '", "horizontalLevel": "' + get_user.horizontalLevel + '", "gender": "' + get_user.gender + '", "date_of_birth": "' + get_user.date_of_birth + '", "wallet": "' + get_user.wallet + '", "email": "' + get_user.email +'"}}'}>{t('AdminSponsorship.Digital_wallet')}</option>
           </select>
           :*/
           <select className="form-control" name="action" value={""} onChange={e => dropDownSelected(e.target.value)}>
             <option value="">{t('AdminSponsorship.Select_action')}</option>
             <option value= {'{"index": "1", "user": {"new_id": "' + get_user.new_id + '", "name": "' + get_user.username + '", "first_name": "' + get_user.first_name + '", "last_name": "' + get_user.last_name + '", "status": "' + get_user.status + '", "deletion_cause": "' + get_user.deletion_cause + '", "parent_username": "' + get_user.parent_username + '", "parent_id": "' + get_user.parent_id + '", "country": "' + get_user.country + '", "phone_number": "' + get_user.phone_number+ '", "address": "' + get_user.address + '", "additional_address": "' + get_user.additional_address + '", "city": "' + get_user.city + '", "postal_code": "' + get_user.postal_code + '", "attachment_type": "' + get_user.attachment_type + '", "attachment_name_front": "' + get_user.attachment_name_front + '", "attachment_name_back": "' + get_user.attachment_name_back + '", "verticalLevel": "' + get_user.verticalLevel + '", "horizontalLevel": "' + get_user.horizontalLevel + '", "gender": "' + get_user.gender + '", "date_of_birth": "' + get_user.date_of_birth + '", "email": "' + get_user.email + '", "date_creation": "' + get_user.date_creation + '", "date_member": "' + get_user.date_member + '", "date_repositioning": "' + get_user.date_repositioning + '", "confirmed": "' + get_user.confirmed + '", "penalty": "' + get_user.penalty + '", "already_repositioned": "' + get_user.already_repositioned +'"}}'}>{t('AdminSponsorship.History')}</option>
             <option value= {'{"index": "3", "user": {"_id": "' + get_user._id + '", "new_id": "' + get_user.new_id + '", "name": "' + get_user.username + '", "first_name": "' + get_user.first_name + '", "last_name": "' + get_user.last_name + '", "status": "' + get_user.status + '", "deletion_cause": "' + get_user.deletion_cause + '", "parent_username": "' + get_user.parent_username + '", "parent_id": "' + get_user.parent_id + '", "country": "' + get_user.country + '", "phone_number": "' + get_user.phone_number+ '", "address": "' + get_user.address + '", "additional_address": "' + get_user.additional_address + '", "city": "' + get_user.city + '", "postal_code": "' + get_user.postal_code + '", "attachment_type": "' + get_user.attachment_type + '", "attachment_name_front": "' + get_user.attachment_name_front + '", "attachment_name_back": "' + get_user.attachment_name_back + '", "verticalLevel": "' + get_user.verticalLevel + '", "horizontalLevel": "' + get_user.horizontalLevel + '", "gender": "' + get_user.gender + '", "date_of_birth": "' + get_user.date_of_birth + '", "date_creation": "' + get_user.date_creation + '", "date_member": "' + get_user.date_member + '", "date_repositioning": "' + get_user.date_repositioning + '", "confirmed": "' + get_user.confirmed + '", "email": "' + get_user.email +'"}}'}>{t('AdminSponsorship.Affiliation_tree')}</option>
             <option value= {'{"index": "5", "user": {"_id": "' + get_user._id + '", "new_id": "' + get_user.new_id + '", "name": "' + get_user.username + '", "first_name": "' + get_user.first_name + '", "last_name": "' + get_user.last_name + '", "status": "' + get_user.status + '", "deletion_cause": "' + get_user.deletion_cause + '", "parent_username": "' + get_user.parent_username + '", "parent_id": "' + get_user.parent_id + '", "country": "' + get_user.country + '", "phone_number": "' + get_user.phone_number+ '", "address": "' + get_user.address + '", "additional_address": "' + get_user.additional_address + '", "city": "' + get_user.city + '", "postal_code": "' + get_user.postal_code + '", "attachment_type": "' + get_user.attachment_type + '", "attachment_name_front": "' + get_user.attachment_name_front + '", "attachment_name_back": "' + get_user.attachment_name_back + '", "verticalLevel": "' + get_user.verticalLevel + '", "horizontalLevel": "' + get_user.horizontalLevel + '", "gender": "' + get_user.gender + '", "date_of_birth": "' + get_user.date_of_birth + '", "wallet": "' + get_user.wallet + '", "email": "' + get_user.email +'"}}'}>{t('AdminSponsorship.Digital_wallet')}</option>
           </select>
          : null 
          }
      </td>
    </tr>
  );
})}

  </tbody>
</table>



</div>
</div>
<div
 style={{
   display: "flex",
   justifyContent: "center",
   alignItems: "center"
 }}>
<div className="scrollme"> 
<table>
{ sortedUsers.length > 0 ? <Pagination usersPerPage={usersPerPage} totalUsers={all_users && sortedUsers.length > 0 ? sortedUsers.length : 0} paginate={paginate} nextPage={nextPage} prevPage={prevPage} firstPage={firstPage} lastPage={lastPage} currentPage={currentPage} /> : null }
</table>
</div>
</div>
  </Fragment>;


var extFront = attachment_name_front.split('.').pop();
var extBack = attachment_name_back.split('.').pop();



const showModal = 
<Modal className="my-modal" show={modalsOpen} onHide={() => setModalIsOpen(false)} as="section">
<ModalHeader closeButton as="span">
  <ModalTitle as="h4">{modalTitle}
</ModalTitle>
</ModalHeader>
<ModalBody as="section" 
 style={{
  whiteSpace: "pre-line"
 }}>
 

{
  
  attachment_type !== "" && attachment_name_front !== "" ?
 <div className="form-group" style={{
   display: "flex",
   alignItems: "center" }}>
        <label>
        {(attachment_type === "I" ? t('AdminSponsorship.Id_card_front') :
    attachment_type === "P" ? t('AdminSponsorship.Passport') : attachment_type === "R" ? t('AdminSponsorship.Resident_card_front') : "") + " : " }</label>
<div style={{width: 50, height: 'auto', display: extFront === "pdf" ? "block" : "none"}}>
 <a href={attachment_name_front} target="_blank"  rel="noopener noreferrer"><img border="0" alt="pdf" src={pdfIcon} /></a></div>
 <a href={attachment_name_front} target="_blank"  rel="noopener noreferrer"> <img src={attachment_name_front} alt="" className="rounded-lg" style = {{ display: extFront !== "pdf" ? 'block' : "none",
  maxWidth:'75px',
  maxHeight:'75px',
  width: 'auto',
  height: 'auto'}}/></a>
</div> : null }
{
  attachment_type !== "" && attachment_name_back !== "" ?
 <div className="form-group" style={{
   display: "flex",
   alignItems: "center" }}>
        <label>
        {(attachment_type === "I" ? t('AdminSponsorship.Id_card_back') :
    attachment_type === "P" ? t('AdminSponsorship.Passport') : attachment_type === "R" ? t('AdminSponsorship.Resident_card_back') : "") + " : " }</label>
<div style={{width: 50, height: 'auto', display: extBack === "pdf" ? "block" : "none"}}>
 <a href={attachment_name_back} target="_blank"  rel="noopener noreferrer"><img border="0" alt="pdf" src={pdfIcon} /></a></div>
 <a href={attachment_name_back} target="_blank"  rel="noopener noreferrer">  <img src={attachment_name_back} alt="" className="rounded-lg" style = {{ display: extBack !== "pdf" ? 'block' : "none",
  maxWidth:'75px',
  maxHeight:'75px',
  width: 'auto',
  height: 'auto'}}/></a>
</div> : null }
{modalFirstButton !== "" ?
        <div className="form-group">
          <p   style={{
    color: "red",
  whiteSpace: "pre-line"
   }}>{deletionAlertCause}</p>
        <select className="form-control" name="action" value={deletionCause} onChange={e => dropDownDeleteSelected(e.target.value)}>
            <option style={{ display : deletionCause === "" ? "" : "none" }} value="">{t('AdminSponsorship.Deletion_cause')}</option>
             <option value='Bad_behaviour'>{t('AdminSponsorship.Bad_behaviour')}</option>
             <option value='Following_his_request'>{t('AdminSponsorship.Following_his_request')}</option>
             <option value='Other'>{t('AdminSponsorship.Other')}</option>
           </select>
        </div>
 : null }

 {modalBody}</ModalBody>
<ModalFooter as="footer">

  {modalFirstButton !== "" ? 
  <>
<button className="btn btn-danger" onClick={() => deleteButtonClicked()}>{modalFirstButton}</button>
</> :
null
  }
    {modalSecondButton !== "" ? 
    
<button className="btn btn-primary" onClick={() => setModalIsOpen(false)}>{modalSecondButton}</button>

 :
 null
  }



</ModalFooter>
</Modal>




const showPaymentModal = 
<Modal className="my-modal" show={paymentModalsOpen} onHide={() => setPaymentModalIsOpen(false)} as="section">
<ModalHeader closeButton as="span">
  <ModalTitle as="h4">{paymentModalTitle}
</ModalTitle>
</ModalHeader>
<ModalBody as="section" 
 style={{
  whiteSpace: "pre-line"
 }}>
 {paymentModalBody}</ModalBody>


 
    <ModalFooter as="footer">
<>
<button className="btn btn-primary" onClick={() => (JSON.parse(JSON.stringify(userPayment.payed_status))) === 1 ? validateInvoiceClicked() : (JSON.parse(JSON.stringify(userPayment.payed_status))) === 2 ? validatePaymentClicked() : setPaymentModalIsOpen(false)}>{t('AdminSponsorship.Yes')}</button>

<button className="btn btn-secondary" onClick={() => setPaymentModalIsOpen(false)}>{t('AdminSponsorship.No')}</button>
</>

</ModalFooter>




</Modal>

      const trees =
      
      <Fragment>
       <SponsorshipActionsAdmin />
      
     
    { all_users !== null && admin_partners !== null ? (
 






<div>
{showTable}








  {all_users && selectedUser ? 

<h1 className='large text-primary'>{switchTree === 0 ? t('AdminSponsorship.Matrix_of') : switchTree === 1 ? (!selectedUser.name || all_users[0]._id + "" === selectedUser._id + "" /*|| user.username === selectedUser.name*/) ?  t('AdminSponsorship.Affiliation_tree') : t('Language.Lang') === "English" ? (selectedUser.name ? selectedUser.name : user.username) + t('AdminSponsorship.Affiliation_tree_of') : (t('Language.Lang') === "Français" || t('Language.Lang') === "Italiano") ? t('AdminSponsorship.Affiliation_tree_of') + " " + (selectedUser.name ? selectedUser.name : user.username) : ""  : switchTree === 2 ? (!selectedUser.name || user._id + "" === selectedUser._id + "") ? t('Language.Lang') === "English" ? user.username + t('AdminSponsorship.Payment_table_of') : (t('Language.Lang') === "Français" || t('Language.Lang') === "Italiano") ? t('AdminSponsorship.Payment_table_of') + " " + user.username : "" : t('Language.Lang') === "English" ? (selectedUser.name ? selectedUser.name : user.username) + t('AdminSponsorship.Payment_table_of') : (t('Language.Lang') === "Français" || t('Language.Lang') === "Italiano") ? t('AdminSponsorship.Payment_table_of') + " " + (selectedUser.name ? selectedUser.name : user.username) : "" : switchTree === 3 ? (!selectedUser.name || user._id + "" === selectedUser._id + "") ? t('Language.Lang') === "English" ? user.username + t('AdminSponsorship.Digital_wallet_of') : (t('Language.Lang') === "Français" || t('Language.Lang') === "Italiano") ? t('AdminSponsorship.Digital_wallet_of') + " " + user.username : "" : t('Language.Lang') === "English" ? (selectedUser.name ? selectedUser.name : user.username) + t('AdminSponsorship.Digital_wallet_of') : (t('Language.Lang') === "Français" || t('Language.Lang') === "Italiano") ? t('AdminSponsorship.Digital_wallet_of') + " " + (selectedUser.name ? selectedUser.name : user.username) : "" : ""}</h1>
/*<h2>bonus && bonus.length > 0 && switchTree === 2 ? t('AdminSponsorship.Position') + ": " + (!selectedUser.name || user.username === selectedUser.name) ? user.verticalLevel + "-" + user.horizontalLevel : selectedUser.verticalLevel + "-" + selectedUser.horizontalLevel : ""</h2>*/

 : null}


 <div
 style={{
   display: "flex",
   justifyContent: "center",
   alignItems: "center"
 }}>

<div className="scrollme"> 
{!loading ? 
switchTree === 0  && admin_partners.length > 0 ?


<StyledTree data={admin_partners}
direction 
onClick={item => showDetails(item) }/>
 :
switchTree === 1 ?
<div>
<StyledTree data={newUserArray}
direction 
onClick={item => showDetails(item) }/>
</div>
 :
 switchTree === 2 ?
 showTableGenealogy
:
switchTree === 3 ?
showTableActivity
:null:
<Spinner />}
</div>
</div>
</div>
    ) : (
<Fragment>
</Fragment>
    )}
  </Fragment>;

      




/*function find(searchData, target, accum=[]){

  target.forEach((f)=>{
    if(f.children !== 0){
      console.log("children" + f.children)
      find(searchData, f.children, accum)
      setLevel(level + 1);
    }
    if(f.id.includes(searchData)){
      accum.push(f);
    }
  });
  return JSON.stringify(accum);
} */
function findByCriteria (name) {

  if (searchCriteria === "P" || searchCriteria === "R" || /*searchCriteria === "D" || searchCriteria === "M" ||*/ searchCriteria === "Y") {
    setSearchField(name.replace(/\D/,''));
  }
  else if (searchCriteria === "D" || searchCriteria === "M") {
    setSearchField(name.replace(/(?!-)[^0-9.]/,''));
  }
  else {
    setSearchField(name);
  }


  if (searchCriteria === "D" && ((name.length === 2 && searchField.length === 1) || (name.length === 5 && searchField.length === 4))){
    setSearchField(name + "-")
}
else if (searchCriteria === "M" && ((name.length === 2 && searchField.length === 1))){
  setSearchField(name + "-")
}


  setCurrentPage(1);
}
/** 
function find(searchData, target, accum=[]){
  target.forEach((f)=>{
    if(f.children){
      find(searchData, f.children, accum)
    }
    if(f.id.includes(searchData)){
      accum.push(f);
    }
  });
  return accum;
}*/

function onClickItem(newUser) {

 // if (user._id !== newUser._id) {


  setAttachment_type("")
  setAttachment_name_front("")
  setAttachment_name_back("")

  setDeletionAlertCause("")


    setModalTitle((JSON.parse(JSON.stringify(newUser.status)) === "C" ? t('AdminSponsorship.Delete_sure') : t('AdminSponsorship.Joker_sure'))+ ': "' +JSON.parse(JSON.stringify(newUser.name))+'"?');
    setModalBody(
      t('AdminSponsorship.Status')+" : " + (JSON.parse(JSON.stringify(newUser.status)) === "C" ? t('AdminSponsorship.Candidate') :
     JSON.parse(JSON.stringify(newUser.status)) === "J" ? t('AdminSponsorship.Joker') :
     JSON.parse(JSON.stringify(newUser.status)) === "A" ? t('AdminSponsorship.Member') :
     JSON.parse(JSON.stringify(newUser.status)) === "B" ? t('AdminSponsorship.Candidate_member') :
     ((JSON.parse(JSON.stringify(newUser.status)) === "D") && (JSON.parse(JSON.stringify(newUser.deletion_cause === "Bad_behaviour")) === "D")) ? t('AdminSponsorship.Removed') :
     JSON.parse(JSON.stringify(newUser.status)) === "D" ? t('AdminSponsorship.Deleted') :
     JSON.parse(JSON.stringify(newUser.status)).charAt(0) === "M" ? t('AdminSponsorship.Actif_member') + JSON.parse(JSON.stringify(newUser.status)).substring(1) : "") +
     "\n"+t('AdminSponsorship.Guarantor')+" : " + (JSON.parse(JSON.stringify(newUser.parent_username)) !== '' ?
     JSON.parse(JSON.stringify(newUser.parent_username)) : t('AdminSponsorship.None'))  +
     "\n"+t('AdminSponsorship.Country')+" : " + t(`Country.${JSON.parse(JSON.stringify(newUser.country))}`) +
     "\n"+t('AdminSponsorship.Position')+" : " + (newUser.horizontalLevel > 0 ? (JSON.parse(JSON.stringify(newUser.verticalLevel.toString() + "-" + newUser.horizontalLevel.toString()))) : t('AdminSponsorship.None_f'))
    );
    setModalFirstButton(JSON.parse(JSON.stringify(newUser.status)) === "C" ? t('AdminSponsorship.Delete') : t('AdminSponsorship.Use_joker'));
    setModalSecondButton("");
    setUserIdDelete(newUser._id)
   setModalIsOpen(true);

/*} 
else {

  setModalTitle(t('AdminSponsorship.No_permission'));
  setModalBody(t('AdminSponsorship.If_persist')
  +"\n\n"+
  t('AdminSponsorship.Ref')+" : " + JSON.parse(JSON.stringify(newUser.new_id))+
  "\n"+t('AdminSponsorship.Username')+" : " + JSON.parse(JSON.stringify(newUser.name)) +
  "\n"+t('AdminSponsorship.Status')+" : " + (JSON.parse(JSON.stringify(newUser.status)) === "C" ? t('AdminSponsorship.Candidate') :
  JSON.parse(JSON.stringify(newUser.status)) === "J" ? t('AdminSponsorship.Joker') :
  JSON.parse(JSON.stringify(newUser.status)).charAt(0) === "M" ? t('AdminSponsorship.Member') + JSON.parse(JSON.stringify(newUser.status)).substring(1) : "") +
  "\n"+t('AdminSponsorship.Guarantor')+" : " + (JSON.parse(JSON.stringify(newUser.parent_username)) !== '' ?
  JSON.parse(JSON.stringify(newUser.parent_username)) : t('AdminSponsorship.None'))  +
  "\n"+t('AdminSponsorship.Country')+" : " + JSON.parse(JSON.stringify(newUser.country)) +
  "\n"+t('AdminSponsorship.Position')+" : " + JSON.parse(JSON.stringify(newUser.verticalLevel.toString() + "-" + newUser.horizontalLevel.toString()))
  
  );
  setModalFirstButton("");
  setModalSecondButton(t('AdminSponsorship.Ok'));
 setModalIsOpen(true);
}*/



   // updateSponsorship(id, parseInt(JSON.stringify(find(id, admin_partners)[0].parent)) + 1, admin_partners);


 // console.log(parseInt(JSON.stringify(find(id, newUserArray)[0].parent)))
}

/*

function onClickItemFromTree(item) {

  if (user._id !== item.id) {

    setModalTitle(t('AdminSponsorship.Delete_sure')+ ": " +item.name+"?");
    setModalBody(
     t('AdminSponsorship.Ref')+" : " + item.new_id+
     "\n"+t('AdminSponsorship.Username')+" : " + item.name+
     "\n"+t('AdminSponsorship.Status')+" : " + (item.status === "C" ? t('AdminSponsorship.Candidate') :
     item.status === "M" ? t('AdminSponsorship.Member') :
     item.status === "M*" ? t('AdminSponsorship.Member')+"*":
     item.status === "M**" ? t('AdminSponsorship.Member')+"**" : "" ) +
     "\n"+t('AdminSponsorship.Guarantor_nickname')+" : " + (item.parent_username !== '' ?
     item.parent_username : t('AdminSponsorship.None'))  +
     "\n"+t('AdminSponsorship.Country')+" : " + item.country +
     "\n"+t('AdminSponsorship.Position')+" : " + (item.verticalLevel.toString() + item.horizontalLevel.toString())
    );
    setModalFirstButton(t('AdminSponsorship.Delete'));
    setModalSecondButton("");
    setUserIdDelete(item.id)
   setModalIsOpen(true);

} 
else {

  setModalTitle(t('AdminSponsorship.No_permission'));
  setModalBody(t('AdminSponsorship.If_persist')
  +"\n\n"+
  t('AdminSponsorship.Ref')+" : " + item.new_id+
  "\n"+t('AdminSponsorship.Username')+" : " + item.name +
  "\n"+t('AdminSponsorship.Status')+" : " + (item.status === "C" ? t('AdminSponsorship.Candidate') :
  item.status === "M" ? t('AdminSponsorship.Member') :
  item.status === "M*" ? t('AdminSponsorship.Member')+"*":
  item.status === "M**" ? t('AdminSponsorship.Member')+"**" : "" ) +
  "\n"+t('AdminSponsorship.Guarantor_nickname')+" : " + (item.parent_username !== '' ?
  item.parent_username : t('AdminSponsorship.None'))  +
  "\n"+t('AdminSponsorship.Country')+" : " + item.country +
  "\n"+t('AdminSponsorship.Position')+" : " + (item.verticalLevel.toString() + item.horizontalLevel.toString())
  );
  setModalFirstButton("");
  setModalSecondButton(t('AdminSponsorship.Ok'));
 setModalIsOpen(true);
}



   // updateSponsorship(id, parseInt(JSON.stringify(find(id, admin_partners)[0].parent)) + 1, admin_partners);


 // console.log(parseInt(JSON.stringify(find(id, newUserArray)[0].parent)))
}
*/

function showDetailsTable(user) {
  setAttachment_type(JSON.parse(JSON.stringify(user.attachment_type)))
  setAttachment_name_front(JSON.parse(JSON.stringify(user.attachment_name_front)))
  setAttachment_name_back(JSON.parse(JSON.stringify(user.attachment_name_back)))
  var date = JSON.parse(JSON.stringify(user.date_of_birth)).substring(0, 10).split("-")
  
  var finalDate = date[1] + '/' + date[2] + '/' + date[0];
  
  if (t('Language.Lang') === "Français" || t('Language.Lang') === "Italiano") {
    finalDate = date[2] + '/' + date[1] + '/' + date[0];
  }


  var dateCreation = JSON.parse(JSON.stringify(user.date_creation))
  var finalDateCreation = ""
  if (dateCreation !== "") {
    dateCreation = JSON.parse(JSON.stringify(user.date_creation)).substring(0, 10).split("-")
    finalDateCreation = dateCreation[1] + '/' + dateCreation[2] + '/' + dateCreation[0];
  
    if (t('Language.Lang') === "Français" || t('Language.Lang') === "Italiano") {
      finalDateCreation = dateCreation[2] + '/' + dateCreation[1] + '/' + dateCreation[0];
    }
  }


  var dateMember = JSON.parse(JSON.stringify(user.date_member))

  var dateMemberArr = dateMember.split(',');

  var finalDateMember = ""
  for(var i=0; i<dateMemberArr.length; i++){
  if (dateMemberArr[i] !== "") {
    dateMember = dateMemberArr[i].substring(0, 10).split("-")
  
    if (t('Language.Lang') === "Français" || t('Language.Lang') === "Italiano") {
      if (i === 0) {
        finalDateMember = `${finalDateMember + dateMember[2] + "/" + dateMember[1] + "/" + dateMember[0]}`;
      }
      else {
        finalDateMember = `${finalDateMember + "\n" + t('AdminSponsorship.Date_member') + " (" + t('AdminSponsorship.Member') + "*".repeat(i) + ") : " + dateMember[2] + '/' + dateMember[1] + '/' + dateMember[0]}`;
      }
    }
    else {
      if (i === 0) {
        finalDateMember = `${finalDateMember + dateMember[1] + '/' + dateMember[2] + '/' + dateMember[0]}`;
      }
      else {
        finalDateMember = `${finalDateMember + "\n" + t('AdminSponsorship.Date_member') + " (" + t('AdminSponsorship.Member') + "*".repeat(i) + ") : " + dateMember[1] + '/' + dateMember[2] + '/' + dateMember[0]}`;
      }
    } 
  }
}

  var dateRepositioning = JSON.parse(JSON.stringify(user.date_repositioning?user.date_repositioning:""))
  var finalDateRepositioning = ""
  if (dateRepositioning !== "") {
    dateRepositioning = JSON.parse(JSON.stringify(user.date_repositioning)).substring(0, 10).split("-")
    finalDateRepositioning = dateRepositioning[1] + '/' + dateRepositioning[2] + '/' + dateRepositioning[0];
  
    if (t('Language.Lang') === "Français" || t('Language.Lang') === "Italiano") {
      finalDateRepositioning = dateRepositioning[2] + '/' + dateRepositioning[1] + '/' + dateRepositioning[0];
    }
  }


  
    setModalTitle(JSON.parse(JSON.stringify(user.name)));
    setModalBody(
    t('AdminSponsorship.Ref')+" : " + JSON.parse(JSON.stringify(user.new_id))+
    "\n"+t('AdminSponsorship.First_name')+" : " + JSON.parse(JSON.stringify(user.first_name)) +
    "\n"+t('AdminSponsorship.Last_name')+" : " + JSON.parse(JSON.stringify(user.last_name)) +
    "\n"+t('AdminSponsorship.Status')+" : " + (JSON.parse(JSON.stringify(user.status)) === "C" ? t('AdminSponsorship.Candidate') :
    ((JSON.parse(JSON.stringify(user.status)) === "D") && (JSON.parse(JSON.stringify(user.deletion_cause)) === "Bad_behaviour")) ? t('AdminSponsorship.Removed') :
    JSON.parse(JSON.stringify(user.status)) === "D" ? t('AdminSponsorship.Deleted') :
    JSON.parse(JSON.stringify(user.status)) === "J" ? t('AdminSponsorship.Joker') :
    JSON.parse(JSON.stringify(user.status)) === "A" ? t('AdminSponsorship.Member') :
    JSON.parse(JSON.stringify(user.status)) === "B" ? t('AdminSponsorship.Candidate_member') :
    JSON.parse(JSON.stringify(user.status)).charAt(0) === "M" ? t('AdminSponsorship.Actif_member') + JSON.parse(JSON.stringify(user.status)).substring(1) : "") +
    (JSON.parse(JSON.stringify(user.deletion_cause)) !== "" ? ("\n"+t('AdminSponsorship.Deletion_cause')+" : "  + (JSON.parse(JSON.stringify(user.deletion_cause)) === 'Automatically' ? t('AdminSponsorship.Automatically') : `${t('AdminSponsorship.'+JSON.parse(JSON.stringify(user.deletion_cause)))}`)) : "") +
    "\n"+t('AdminSponsorship.Guarantor')+" : " + (JSON.parse(JSON.stringify(user.parent_username)) !== '' ?
    JSON.parse(JSON.stringify(user.parent_username)) : t('AdminSponsorship.None'))  +
    "\n"+t('AdminSponsorship.Position')+" : " + (user.horizontalLevel > 0 ? JSON.parse(JSON.stringify(user.verticalLevel.toString() + "-" + user.horizontalLevel.toString())) : t('AdminSponsorship.None_f'))+
    "\n"+t('AdminSponsorship.Gender')+" : " + (JSON.parse(JSON.stringify(user.gender)) === "M" ? t('AdminSponsorship.Male') :
    JSON.parse(JSON.stringify(user.gender)) === "F" ? t('AdminSponsorship.Female') : "" ) +
    "\n"+t('AdminSponsorship.Date_birth')+" : "  + finalDate +
    "\n"+t('AdminSponsorship.Email_address')+" : " + JSON.parse(JSON.stringify(user.email)) +
    "\n"+t('AdminSponsorship.Country')+" : " + t(`Country.${JSON.parse(JSON.stringify(user.country))}`) +
    "\n"+t('AdminSponsorship.Phone_number')+" : " + JSON.parse(JSON.stringify(user.phone_number)) +
    "\n"+t('AdminSponsorship.Address')+" : " + JSON.parse(JSON.stringify(user.address)) +
    (user.additional_address !== "" ? ("\n"+t('AdminSponsorship.Additional_address')+" : "  + JSON.parse(JSON.stringify(user.additional_address))) : "") +
    "\n"+t('AdminSponsorship.City')+" : " + JSON.parse(JSON.stringify(user.city)) +
    "\n"+t('AdminSponsorship.Postal_code')+" : " + JSON.parse(JSON.stringify(user.postal_code)) +
    "\n"+t('AdminSponsorship.Penalty')+" : " + JSON.parse(JSON.stringify(user.penalty)) +
    "\n"+t('AdminSponsorship.Old_account')+" : " + (JSON.parse(JSON.stringify(user.already_repositioned)) !== "0" ? t('AdminSponsorship.Yes') : t('AdminSponsorship.No'))+
    (finalDateCreation !== "" ? ("\n"+t('AdminSponsorship.Subscription_date')+" : "  + finalDateCreation) : "") +
    (finalDateMember !== "" ? ("\n"+t('AdminSponsorship.Date_member')+" (" + t('AdminSponsorship.Member')+") : "  + finalDateMember) : "") +
    (finalDateRepositioning !== "" ? ("\n"+t('AdminSponsorship.Date_repositioning')+" : "  + finalDateRepositioning) : "")
    );
    setModalFirstButton("");
    setModalSecondButton(t('AdminSponsorship.Ok'));
   setModalIsOpen(true);
  }


function showDetails(newUser) {
  setAttachment_type("")
  setAttachment_name_front("")
  setAttachment_name_back("")
  var dateCreation = JSON.parse(JSON.stringify(newUser.date_creation))
  var finalDateCreation = ""
  if (dateCreation !== "") {
    dateCreation = JSON.parse(JSON.stringify(newUser.date_creation)).substring(0, 10).split("-")
    finalDateCreation = dateCreation[1] + '/' + dateCreation[2] + '/' + dateCreation[0];
  
    if (t('Language.Lang') === "Français" || t('Language.Lang') === "Italiano") {
      finalDateCreation = dateCreation[2] + '/' + dateCreation[1] + '/' + dateCreation[0];
    }
  }



  var dateMember = JSON.parse(JSON.stringify(newUser.date_member))

  var dateMemberArr = dateMember.split(',');

  var finalDateMember = ""
  for(var i=0; i<dateMemberArr.length; i++){
  if (dateMemberArr[i] !== "") {
    dateMember = dateMemberArr[i].substring(0, 10).split("-")
  
    if (t('Language.Lang') === "Français" || t('Language.Lang') === "Italiano") {
      if (i === 0) {
        finalDateMember = `${finalDateMember + dateMember[2] + "/" + dateMember[1] + "/" + dateMember[0]}`;
      }
      else {
        finalDateMember = `${finalDateMember + "\n" + t('AdminSponsorship.Date_member') + " (" + t('AdminSponsorship.Member') + "*".repeat(i) + ") : " + dateMember[2] + '/' + dateMember[1] + '/' + dateMember[0]}`;
      }
    }
    else {
      if (i === 0) {
        finalDateMember = `${finalDateMember + dateMember[1] + '/' + dateMember[2] + '/' + dateMember[0]}`;
      }
      else {
        finalDateMember = `${finalDateMember + "\n" + t('AdminSponsorship.Date_member') + " (" + t('AdminSponsorship.Member') + "*".repeat(i) + ") : " + dateMember[1] + '/' + dateMember[2] + '/' + dateMember[0]}`;
      }
    }  
  }
}


  var dateRepositioning = JSON.parse(JSON.stringify(newUser.date_repositioning?newUser.date_repositioning:""))
  var finalDateRepositioning = ""
  if (dateRepositioning !== "") {
    dateRepositioning = JSON.parse(JSON.stringify(newUser.date_repositioning)).substring(0, 10).split("-")
    finalDateRepositioning = dateRepositioning[1] + '/' + dateRepositioning[2] + '/' + dateRepositioning[0];
  
    if (t('Language.Lang') === "Français" || t('Language.Lang') === "Italiano") {
      finalDateRepositioning = dateRepositioning[2] + '/' + dateRepositioning[1] + '/' + dateRepositioning[0];
    }
  }
    setModalTitle(JSON.parse(JSON.stringify(newUser.name)));
    setModalBody(
    t('AdminSponsorship.Status')+" : " + (JSON.parse(JSON.stringify(newUser.status)) === "C" ? t('AdminSponsorship.Candidate') :
    JSON.parse(JSON.stringify(newUser.status)) === "J" ? t('AdminSponsorship.Joker') :
    JSON.parse(JSON.stringify(newUser.status)) === "A" ? t('AdminSponsorship.Member') :
    JSON.parse(JSON.stringify(newUser.status)) === "B" ? t('AdminSponsorship.Candidate_member') :
    ((JSON.parse(JSON.stringify(newUser.status)) === "D") && (JSON.parse(JSON.stringify(newUser.deletion_cause)) === "Bad_behaviour")) ? t('AdminSponsorship.Removed') :
    JSON.parse(JSON.stringify(newUser.status)) === "D" ? t('AdminSponsorship.Deleted') :
    JSON.parse(JSON.stringify(newUser.status)).charAt(0) === "M" ? t('AdminSponsorship.Actif_member') + JSON.parse(JSON.stringify(newUser.status)).substring(1) : "") +
    "\n"+t('AdminSponsorship.Guarantor')+" : " + (JSON.parse(JSON.stringify(newUser.parent_username)) !== '' ?
    JSON.parse(JSON.stringify(newUser.parent_username)) : t('AdminSponsorship.None'))  +
    "\n"+t('AdminSponsorship.Country')+" : " + t(`Country.${JSON.parse(JSON.stringify(newUser.country))}`) +
    "\n"+t('AdminSponsorship.Level')+" : " + JSON.parse(JSON.stringify(newUser.verticalLevel - (selectedUser ? selectedUser.verticalLevel : user.verticalLevel))) +
    (finalDateCreation !== "" ? ("\n"+t('AdminSponsorship.Subscription_date')+" : "  + finalDateCreation) : "") +
    (finalDateMember !== "" ? ("\n"+t('AdminSponsorship.Date_member')+" (" + t('AdminSponsorship.Member')+") : " + finalDateMember) : "") +
    (finalDateRepositioning !== "" ? ("\n"+t('AdminSponsorship.Date_repositioning')+" : "  + finalDateRepositioning) : "")
    );
    setModalFirstButton("");
    setModalSecondButton(t('AdminSponsorship.Ok'));
   setModalIsOpen(true);
  }



function deleteButtonClicked() {
 /* if (userIdDelete === selectedUser._id) {
  deleteUser(userIdDelete, user._id, username, null);
  setSelectedUser(0)
    setSwitchTree(0);
  }
  else{
    deleteUser(userIdDelete, user._id, username, selectedUser._id);
  }*/
  /*if (userIdDelete === selectedUser._id) {
    deleteUser(userIdDelete, user._id, null);
    getPartners(user._id)
    setSelectedUser(user)
    setModalIsOpen(false);
 //   setSelectedUser(0)
  }
  else {*/
    if (deletionCause === "") {
      setDeletionAlertCause(t('AdminSponsorship.Cause_required'))
    }
    else {
      deleteUser(userIdDelete, user._id, switchTree, selectedUser._id, deletionCause);

      setModalIsOpen(false);
    } 
  //}

//  setCriteria("A");
}

function validateInvoiceClicked() {
  validateInvoice((JSON.parse(JSON.stringify(userPayment._id))), user._id);
 
  setPaymentModalIsOpen(false);
 }

 function validatePaymentClicked() {
  validatePayment((JSON.parse(JSON.stringify(userPayment._id))), user._id);

  setPaymentModalIsOpen(false);
  } 


function onClickPaymentItem(newUser) {
  setUserPayment(newUser);
     setPaymentModalTitle((newUser.payed_status === 1 ? t('AdminSponsorship.Invoice_sent_to') : t('AdminSponsorship.Validate_payment_for'))+ ': "' + newUser.username + '"?');
     setPaymentModalBody(
      (newUser.payed_status === 1 ? t('AdminSponsorship.Invoice_sure') : t('AdminSponsorship.Payment_sure'))+ ': "' + newUser.username + '"?');
     setPaymentModalIsOpen(true);
 }


const dropDownSelected = event => {
  if (JSON.parse(event).index === "1") {
    showDetailsTable(JSON.parse(event).user);
  }
  else if (JSON.parse(event).index === "2") {

  /*  setSwitchTree(0); */
    setSelectedUser(JSON.parse(event).user)
  //  getAdminPartners(JSON.parse(event).id);
 // getAdminPartners(JSON.parse(event).user._id);
  history.push({
    pathname: '/admin/matrix',
    state: { selectedUser: JSON.parse(event).user }
  })
}
  else if (JSON.parse(event).index === "3") {
    setSwitchTree(1);
    setSelectedUser(JSON.parse(event).user)
    getPartners(JSON.parse(event).user._id)
    window.scrollTo({top: document.body.scrollHeight, left: 0, behavior: 'smooth' });
  }
  else if (JSON.parse(event).index === "4") {
    setSwitchTree(2);
    if (bonus_other && bonus_other !== undefined) {
    setSelectedUser(JSON.parse(event).user);
    getGenealogies(JSON.parse(event).user._id, false, true);
    getSponsorships(JSON.parse(event).user._id, false, true);
  }
    window.scrollTo({top: document.body.scrollHeight, left: 0, behavior: 'smooth' });
  }
  else if (JSON.parse(event).index === "5") {
    setSwitchTree(3);
    setCurrentPageTransaction(1);
    setSelectedUser(JSON.parse(event).user);
    getTransactions(JSON.parse(event).user._id, true);
 //   getSponsorships(JSON.parse(event).user._id);
    window.scrollTo({top: document.body.scrollHeight, left: 0, behavior: 'smooth' });
  }
  else if (JSON.parse(event).index === "6") {
    onClickItem(JSON.parse(event).user);
  }
}


const dropDownDeleteSelected = event => {
  setDeletionAlertCause("")
    setDeletionCause(event)
}



/*
function onClickItem(id) {

  // console.log("jsonArr" + JSON.stringify(newUserArray) )
 

 //    updateSponsorship(id, parseInt(JSON.stringify(find(id, newUserArray)[0].parent)) + 1, newUserArray);
  /*   alert(t('Sponsorship.Username')+" : " + JSON.stringify(find(id, newUserArray)[0].name) +
     "\n"+t('Sponsorship.Guarantor_nickname')+" : " + JSON.stringify(find(id, newUserArray)[0].parent_username) +
     "\n"+t('Sponsorship.Country')+" : " + JSON.stringify(find(id, newUserArray)[0].country) +
     "\n"+t('Sponsorship.Status')+" : " + (JSON.stringify(find(id, newUserArray)[0].status) === "\"C\"" ? "\""+t('Sponsorship.Candidate')+"\"" :
     JSON.stringify(find(id, newUserArray)[0].status) === "\"M\"" ? "\""+t('Sponsorship.Member')+"\"" :
     JSON.stringify(find(id, newUserArray)[0].status) === "\"M*\"" ? "\""+t('Sponsorship.Member')+"*\"":
     JSON.stringify(find(id, newUserArray)[0].status) === "\"M**\"" ? "\""+t('Sponsorship.Member')+"**\"" : "") +
     "\n"+t('Sponsorship.Level')+" : " + JSON.stringify(find(id, newUserArray)[0].verticalLevel.toString() + find(id, newUserArray)[0].horizontalLevel.toString()));

  // console.log(parseInt(JSON.stringify(find(id, newUserArray)[0].parent)))
}*/

/*function onRefreshItem(id, i) {
  getAdminPartners(id);
 // setNewIndex(i)
}*/



return loading && all_users === null && admin_partners === null ? (
  <Spinner />
) : (
  
  <Fragment>

    {showModal}
    {showPaymentModal}
  {trees}



</Fragment>
);
};


SponsorshipTreeAdmin.propTypes = {
  getAllUsers: PropTypes.func.isRequired,
  getPartners: PropTypes.func.isRequired,
  getGenealogies: PropTypes.func.isRequired,
  deleteUser: PropTypes.func.isRequired,
  validateInvoice: PropTypes.func.isRequired,
  validatePayment: PropTypes.func.isRequired,
  sponsorship: PropTypes.object.isRequired,
  transaction: PropTypes.object.isRequired,
  getSponsorships: PropTypes.func.isRequired,
  getTransactions: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  getInvoice: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  sponsorship: state.sponsorship,
  transaction: state.transaction,
  auth: state.auth
});

export default connect(
  mapStateToProps,
  { getAllUsers, getPartners, deleteUser, validateInvoice, validatePayment, getGenealogies, getSponsorships, getTransactions, getInvoice }
)(SponsorshipTreeAdmin);


