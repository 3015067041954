import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Spinner from '../../layout/Spinner';
import SponsorshipActionsAdmin from './SponsorshipActionsAdmin';
import Tree, { withStyles } from 'react-vertical-tree';
import { getAdminPartners, getSponsorships } from '../../../actions/sponsorship';
import Modal from "react-bootstrap/Modal";
import ModalBody from "react-bootstrap/ModalBody";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalFooter from "react-bootstrap/ModalFooter";
import ModalTitle from "react-bootstrap/ModalTitle";
import { useTranslation } from 'react-i18next';
import { useLocation } from "react-router-dom";
import { Redirect } from 'react-router-dom';


const MatrixAdmin = ({ getAdminPartners, getSponsorships, auth: { user, theme }, sponsorship: { admin_partners, loading } }) => {

  const location = useLocation();
 // const [newIndex, setNewIndex] = useState(0);
  const [modalsOpen, setModalIsOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalBody, setModalBody] = useState("");
  const [modalFirstButton, setModalFirstButton] = useState("");


  /*const styles = {
    lines: {
      color: '#1890ff',
      height: '90px',
    },
    node: {
      backgroundColor: '#1890ff',
      border: '1px solid #1890ff',
    },
    text: {
      color: '#fff',
    }
  }*/
  const styles = {
    lines: {
      color: theme === "light" ? '#bc9f51' : '#edd99a',
      height: '90px',
    },
    node: {
      backgroundColor: theme === "light" ? '#bc9f51' : '#edd99a',
      border: '1px solid',
      borderColor: theme === "light" ? '#bc9f51' : '#edd99a',
    },
    text: {
      color: theme === "light" ? '#fff' : '#000',
      fontWeight: 'bold'
    }
  }
  const StyledTree = withStyles(styles)(Tree)



/*  useEffect(() => {
    document.body.style = 'background: #EDAE6A'; //#DCDCDC

    // returned function will be called on component unmount 
    return () => {
      document.body.style = 'background: #D3D3D3'; //#DCDCDC
    }
  }, []); */

  useEffect(() => {
    getAdminPartners( location.state ? location.state.selectedUser._id : user._id);

  window.scrollTo({top: document.body.scrollHeight / 2, left: 0, behavior: 'smooth' });
  }, [getAdminPartners, location, user._id]);


  /*useEffect(() => {
    getSponsorships(user._id);
  }, [getSponsorships, user._id]);*/




  const { t } = useTranslation();



  if ( user.role !== 0 ) {
    return <Redirect to="/dashboard" />;
  }






const showModal = 
<Modal className="my-modal" show={modalsOpen} onHide={() => setModalIsOpen(false)} as="section">
<ModalHeader closeButton as="span">
  <ModalTitle as="h4">{modalTitle}</ModalTitle>
</ModalHeader>
<ModalBody as="section" 
 style={{
  whiteSpace: "pre-line"
 }}>{modalBody}</ModalBody>
<ModalFooter as="footer">


    {modalFirstButton !== "" ? 
<button className="btn btn-primary" onClick={() => setModalIsOpen(false)}>{modalFirstButton}</button> :
null
  }


</ModalFooter>
</Modal>

      const trees =
      
      <Fragment>
       <SponsorshipActionsAdmin />
      
     
    { admin_partners !== null ? (
 






<div>












<h1 className='large text-primary'>{ (!location.state || user._id + "" === location.state.selectedUser._id + "") ? t('Language.Lang') === "English" ? user.username + t('AdminSponsorship.Matrix_of') : (t('Language.Lang') === "Français" || t('Language.Lang') === "Italiano") ? t('AdminSponsorship.Matrix_of') + " " + user.username : "" : t('Language.Lang') === "English" ? (location.state ? location.state.selectedUser.name : user.username) + t('AdminSponsorship.Matrix_of') : (t('Language.Lang') === "Français" || t('Language.Lang') === "Italiano") ? t('AdminSponsorship.Matrix_of') + " " + (location.state ? location.state.selectedUser.name : user.username) : ""}</h1>
 <div
 style={{
   display: "flex",
   justifyContent: "center",
   alignItems: "center"
 }}>

<div className="scrollme"> 
{!loading ? 
 admin_partners.length > 0 ?

<div>

<StyledTree data={admin_partners}
direction 
onClick={item => showDetails(item) }/>

</div>
 :null:
<Spinner />}
</div>
</div>
</div>
    ) : (
<Fragment>
</Fragment>
    )}
  </Fragment>;

      




/*function find(searchData, target, accum=[]){

  target.forEach((f)=>{
    if(f.children !== 0){
      console.log("children" + f.children)
      find(searchData, f.children, accum)
      setLevel(level + 1);
    }
    if(f.id.includes(searchData)){
      accum.push(f);
    }
  });
  return JSON.stringify(accum);
} */
/** 
function find(searchData, target, accum=[]){
  target.forEach((f)=>{
    if(f.children){
      find(searchData, f.children, accum)
    }
    if(f.id.includes(searchData)){
      accum.push(f);
    }
  });
  return accum;
}*/


/*

function onClickItemFromTree(item) {

  if (user._id !== item.id) {

    setModalTitle(t('AdminSponsorship.Delete_sure')+ ": " +item.name+"?");
    setModalBody(
     t('AdminSponsorship.Ref')+" : " + item.new_id+
     "\n"+t('AdminSponsorship.Username')+" : " + item.name+
     "\n"+t('AdminSponsorship.Status')+" : " + (item.status === "C" ? t('AdminSponsorship.Candidate') :
     item.status === "M" ? t('AdminSponsorship.Member') :
     item.status === "M*" ? t('AdminSponsorship.Member')+"*":
     item.status === "M**" ? t('AdminSponsorship.Member')+"**" : "" ) +
     "\n"+t('AdminSponsorship.Guarantor_nickname')+" : " + (item.parent_username !== '' ?
     item.parent_username : t('AdminSponsorship.None'))  +
     "\n"+t('AdminSponsorship.Country')+" : " + item.country +
     "\n"+t('AdminSponsorship.Position')+" : " + (item.verticalLevel.toString() + item.horizontalLevel.toString())
    );
    setModalFirstButton(t('AdminSponsorship.Delete'));
    setModalSecondButton("");
    setUserIdDelete(item.id)
   setModalIsOpen(true);

} 
else {

  setModalTitle(t('AdminSponsorship.No_permission'));
  setModalBody(t('AdminSponsorship.If_persist')
  +"\n\n"+
  t('AdminSponsorship.Ref')+" : " + item.new_id+
  "\n"+t('AdminSponsorship.Username')+" : " + item.name +
  "\n"+t('AdminSponsorship.Status')+" : " + (item.status === "C" ? t('AdminSponsorship.Candidate') :
  item.status === "M" ? t('AdminSponsorship.Member') :
  item.status === "M*" ? t('AdminSponsorship.Member')+"*":
  item.status === "M**" ? t('AdminSponsorship.Member')+"**" : "" ) +
  "\n"+t('AdminSponsorship.Guarantor_nickname')+" : " + (item.parent_username !== '' ?
  item.parent_username : t('AdminSponsorship.None'))  +
  "\n"+t('AdminSponsorship.Country')+" : " + item.country +
  "\n"+t('AdminSponsorship.Position')+" : " + (item.verticalLevel.toString() + item.horizontalLevel.toString())
  );
  setModalFirstButton("");
  setModalSecondButton(t('AdminSponsorship.Ok'));
 setModalIsOpen(true);
}



   // updateSponsorship(id, parseInt(JSON.stringify(find(id, admin_partners)[0].parent)) + 1, admin_partners);


 // console.log(parseInt(JSON.stringify(find(id, newUserArray)[0].parent)))
}
*/




function showDetails(newUser) {
  var dateCreation = JSON.parse(JSON.stringify(newUser.date_creation))
  var finalDateCreation = ""
  if (dateCreation !== "") {
    dateCreation = JSON.parse(JSON.stringify(newUser.date_creation)).substring(0, 10).split("-")
    finalDateCreation = dateCreation[1] + '/' + dateCreation[2] + '/' + dateCreation[0];
  
    if (t('Language.Lang') === "Français" || t('Language.Lang') === "Italiano") {
      finalDateCreation = dateCreation[2] + '/' + dateCreation[1] + '/' + dateCreation[0];
    }
  }




  var dateMember = JSON.parse(JSON.stringify(newUser.date_member))

  var dateMemberArr = dateMember.split(',');

  var finalDateMember = ""
  for(var i=0; i<dateMemberArr.length; i++){
  if (dateMemberArr[i] !== "") {
    dateMember = dateMemberArr[i].substring(0, 10).split("-")
  
    if (t('Language.Lang') === "Français" || t('Language.Lang') === "Italiano") {
      if (i === 0) {
        finalDateMember = `${finalDateMember + dateMember[2] + "/" + dateMember[1] + "/" + dateMember[0]}`;
      }
      else {
        finalDateMember = `${finalDateMember + "\n" + t('AdminSponsorship.Date_member') + " (" + t('AdminSponsorship.Member') + "*".repeat(i) + ") : " + dateMember[2] + '/' + dateMember[1] + '/' + dateMember[0]}`;
      }
    }
    else {
      if (i === 0) {
        finalDateMember = `${finalDateMember + dateMember[1] + '/' + dateMember[2] + '/' + dateMember[0]}`;
      }
      else {
        finalDateMember = `${finalDateMember + "\n" + t('AdminSponsorship.Date_member') + " (" + t('AdminSponsorship.Member') + "*".repeat(i) + ") : " + dateMember[1] + '/' + dateMember[2] + '/' + dateMember[0]}`;
      }
    } 
  }
}



  var dateRepositioning = JSON.parse(JSON.stringify(newUser.date_repositioning?newUser.date_repositioning:""))
  var finalDateRepositioning = ""
  if (dateRepositioning !== "") {
    dateRepositioning = JSON.parse(JSON.stringify(newUser.date_repositioning?newUser.date_repositioning:"")).substring(0, 10).split("-")
    finalDateRepositioning = dateRepositioning[1] + '/' + dateRepositioning[2] + '/' + dateRepositioning[0];
  
    if (t('Language.Lang') === "Français" || t('Language.Lang') === "Italiano") {
      finalDateRepositioning = dateRepositioning[2] + '/' + dateRepositioning[1] + '/' + dateRepositioning[0];
    }
  }
  
    setModalTitle(JSON.parse(JSON.stringify(newUser.name)));
    setModalBody(
    t('AdminSponsorship.Status')+" : " + (JSON.parse(JSON.stringify(newUser.status)) === "C" ? t('AdminSponsorship.Candidate') :
    JSON.parse(JSON.stringify(newUser.status)) === "J" ? t('AdminSponsorship.Joker') :
    JSON.parse(JSON.stringify(newUser.status)) === "A" ? t('AdminSponsorship.Member') :
    JSON.parse(JSON.stringify(newUser.status)) === "B" ? t('AdminSponsorship.Candidate_member') :
    JSON.parse(JSON.stringify(newUser.status)).charAt(0) === "M" ? t('AdminSponsorship.Actif_member') + JSON.parse(JSON.stringify(newUser.status)).substring(1) : "") +
    "\n"+t('AdminSponsorship.Guarantor')+" : " + (JSON.parse(JSON.stringify(newUser.parent_username)) !== '' ?
    JSON.parse(JSON.stringify(newUser.parent_username)) : t('AdminSponsorship.None'))  +
    "\n"+t('AdminSponsorship.Country')+" : " + t(`Country.${JSON.parse(JSON.stringify(newUser.country))}`) +
    "\n"+t('AdminSponsorship.Level')+" : " + JSON.parse(JSON.stringify(newUser.verticalLevel - (location ? location.state.selectedUser.verticalLevel : user.verticalLevel))) +

 //   "\n"+t('AdminSponsorship.Level')+" : " + JSON.parse(JSON.stringify(newUser.verticalLevel + "-" + newUser.horizontalLevel)) +
    (finalDateCreation !== "" ? ("\n"+t('AdminSponsorship.Subscription_date')+" : "  + finalDateCreation) : "") +
    (finalDateMember !== "" ? ("\n"+t('AdminSponsorship.Date_member')+" (" + t('AdminSponsorship.Member')+") : "  + finalDateMember) : "") +
    (finalDateRepositioning !== "" ? ("\n"+t('AdminSponsorship.Date_repositioning')+" : "  + finalDateRepositioning) : "")
    );
    setModalFirstButton("");
    setModalFirstButton(t('AdminSponsorship.Ok'));
   setModalIsOpen(true);
  }









/*
function onClickItem(id) {

  // console.log("jsonArr" + JSON.stringify(newUserArray) )
 

 //    updateSponsorship(id, parseInt(JSON.stringify(find(id, newUserArray)[0].parent)) + 1, newUserArray);
  /*   alert(t('Sponsorship.Username')+" : " + JSON.stringify(find(id, newUserArray)[0].name) +
     "\n"+t('Sponsorship.Guarantor_nickname')+" : " + JSON.stringify(find(id, newUserArray)[0].parent_username) +
     "\n"+t('Sponsorship.Country')+" : " + JSON.stringify(find(id, newUserArray)[0].country) +
     "\n"+t('Sponsorship.Status')+" : " + (JSON.stringify(find(id, newUserArray)[0].status) === "\"C\"" ? "\""+t('Sponsorship.Candidate')+"\"" :
     JSON.stringify(find(id, newUserArray)[0].status) === "\"M\"" ? "\""+t('Sponsorship.Member')+"\"" :
     JSON.stringify(find(id, newUserArray)[0].status) === "\"M*\"" ? "\""+t('Sponsorship.Member')+"*\"":
     JSON.stringify(find(id, newUserArray)[0].status) === "\"M**\"" ? "\""+t('Sponsorship.Member')+"**\"" : "") +
     "\n"+t('Sponsorship.Level')+" : " + JSON.stringify(find(id, newUserArray)[0].verticalLevel.toString() + find(id, newUserArray)[0].horizontalLevel.toString()));

  // console.log(parseInt(JSON.stringify(find(id, newUserArray)[0].parent)))
}*/

/*function onRefreshItem(id, i) {
  getAdminPartners(id);
 // setNewIndex(i)
}*/



return loading && admin_partners === null ? (
  <Spinner />
) : (
  
  <Fragment>

    {showModal}
  {trees}



</Fragment>
);
};


MatrixAdmin.propTypes = {
  getAdminPartners: PropTypes.func.isRequired,
  sponsorship: PropTypes.object.isRequired,
  getSponsorships: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  sponsorship: state.sponsorship,
  auth: state.auth
});

export default connect(
  mapStateToProps,
  { getAdminPartners, getSponsorships }
)(MatrixAdmin);


